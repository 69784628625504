import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import _ from 'lodash';
import { statuses } from '../../common/Warehouses';
import { getBillProductQuantity, getBillQuantityByStatus, getBillQuantityByType } from './dashboardAPI';
import axios from 'axios';

const initialState = {
    billByStatus: undefined,
    billProduct: undefined,
    inputByType: undefined,
    outputByType: undefined,
    status: {
        status: "idle",
        product: "idle",
        inputType: "idle",
        outputType: "idle",
    },
    error: undefined,
};

export const getBillQuantityByStatusAsync = createAsyncThunk(
    'dashboard/byStatus',
    async (params, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await getBillQuantityByStatus(params, {
                cancelToken: source.token,
            });
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getBillProductQuantityAsync = createAsyncThunk(
    'dashboard/product',
    async (params, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await getBillProductQuantity(params, {
                cancelToken: source.token,
            });
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getBillQuantityByTypeAsync = createAsyncThunk(
    'dashboard/byType',
    async (params, thunkAPI) => {
        try {
            const source = axios.CancelToken.source()
            thunkAPI.signal.addEventListener('abort', () => {
                source.cancel()
            })
            const response = await getBillQuantityByType(params.filter, {
                cancelToken: source.token,
            });
            // The value we return becomes the `fulfilled` action payload
            return { data: response.data, type: params.type };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const DashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setStatus: (state, action) => {
            let [key] = Object.keys(action.payload)
            state.status[key] = action.payload[key]
        },
        clearDashboard: state => {
            state.list = undefined;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getBillQuantityByStatusAsync.pending, (state) => {
                state.status.status = 'loading';
            })
            .addCase(getBillQuantityByStatusAsync.fulfilled, (state, action) => {
                state.billByStatus = action.payload

                state.status.status = 'idle';
                state.error = undefined;
            })
            .addCase(getBillQuantityByStatusAsync.rejected, (state, action) => {
                state.status.status = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getBillQuantityByStatus"
                    }
                }
            })
            .addCase(getBillProductQuantityAsync.pending, (state) => {
                state.status.product = 'loading';
            })
            .addCase(getBillProductQuantityAsync.fulfilled, (state, action) => {
                state.billProduct = action.payload

                state.status.product = 'idle';
                state.error = undefined;
            })
            .addCase(getBillProductQuantityAsync.rejected, (state, action) => {
                state.status.product = 'idle';
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getBillProduct"
                    }
                }
            })
            .addCase(getBillQuantityByTypeAsync.pending, (state, action) => {
                const { type } = action.meta.arg
                if (type === "PO") {
                    state.status.inputType = 'loading';
                }
                else {
                    state.status.outputType = 'loading';
                }
            })
            .addCase(getBillQuantityByTypeAsync.fulfilled, (state, action) => {
                if (action.payload.type === "PO") {
                    state.inputByType = action.payload.data
                    state.status.inputType = 'idle';
                }
                else {
                    state.outputByType = action.payload.data
                    state.status.outputType = 'idle';
                }

                state.error = undefined;
            })
            .addCase(getBillQuantityByTypeAsync.rejected, (state, action) => {
                const { type } = action.meta.arg
                if (type === "PO") {
                    state.status.inputType = 'idle';
                }
                else {
                    state.status.outputType = 'idle';
                }
                if (action?.error?.name !== "AbortError") {
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getBillQuantityByType"
                    }
                }
            })
    },
});

export const selectQuantityByStatus = state => state.dashboard.billByStatus
export const selectBillProduct = state => state.dashboard.billProduct
export const selectInputQuantityByType = state => state.dashboard.inputByType
export const selectOutputQuantityByType = state => state.dashboard.outputByType
export const selectStatus = state => state.dashboard.status
export const selectError = state => state.dashboard.error

export const { setStatus, clearDashboard } = DashboardSlice.actions;

export default DashboardSlice.reducer;

