import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

export const getInventoryReports = (params, option) => {
  return axiosClient.get("summary-stocks", {
    params,
    ...option,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export const getImportReports = (params, option) => {
  return axiosClient.get("input-bill-details-static", {
    params,
    ...option,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export const getExportReports = (params, option) => {
  return axiosClient.get("output-bill-detail-position-statics", {
    params,
    ...option,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export const getWarehouseCardReports = (params, option) => {
  return axiosClient.get("stock_cards", {
    params,
    ...option,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
