import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getLocations,
    getLocationDetail,
    postLocation,
    putLocation,
} from './locationAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
};

export const getLocationsAsync = createAsyncThunk(
    'locations',
    async (params, thunkAPI) => {
        try {
            const response = await getLocations(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getLocationDetailAsync = createAsyncThunk(
    'locationDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getLocationDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const postLocationAsync = createAsyncThunk(
    'postLocation',
    async (data, thunkAPI) => {
        try {
            const response = await postLocation(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putLocationAsync = createAsyncThunk(
    'putLocation',
    async (data, thunkAPI) => {
        try {
            const response = await putLocation(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putLocationStatusAsync = createAsyncThunk(
    'putLocationStatus',
    async (data, thunkAPI) => {
        try {
            const response = await putLocation(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const locationSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearLocationDetail: state => {
            state.detail = undefined;
        },
        clearLocations: state => {
            state.list = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getLocationsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getLocationsAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getLocationsAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getLocations"
                    }
                }
            })
            .addCase(getLocationDetailAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(getLocationDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getLocationDetailAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getLocationDetail"
                    }
                }
            })
            .addCase(postLocationAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(postLocationAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putLocationAsync.pending, (state) => {
                state.status = 'update_loading';
            })
            .addCase(putLocationAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                message.success("Cập nhật thành công!")
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putLocationAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Cập nhật thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putLocation"
                    }
                }
            })
            .addCase(putLocationStatusAsync.pending, (state) => {
                state.status = 'update_status_loading';
            })
            .addCase(putLocationStatusAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putLocationStatusAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Thay đổi trạng thái thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putLocationStatus"
                    }
                }
            })
    },
});

export const selectLocations = state => state.locations.list
export const selectLocationDetail = state => state.locations.detail
export const locationsStatus = state => state.locations.status
export const locationsError = state => state.locations.error

export const { clearLocationDetail, clearLocations, setIdleStatus } = locationSlice.actions;

export default locationSlice.reducer;

