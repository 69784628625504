import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getNews = (params) => {
    return axiosClient.get("news", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getNews,
}