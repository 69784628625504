import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getCustomers = (params, options) => {
  return axiosClient.get("customers", {
    params,
    ...options,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getCustomerDetail = (id) => {
  return axiosClient.get("customers/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postCustomer = (data) => {
  return axiosClient.post("customers", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putCustomer = (data) => {
  return axiosClient.put("customers/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export { getCustomers, getCustomerDetail, postCustomer, putCustomer };
