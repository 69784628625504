import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getUserGroupDetail, getUserGroups, putUserGroup } from './userGroupAPI';

let initialState = {
    list: undefined,
    detail: undefined,
    status: {
        list: "idile",
        detail: "idile",
    },
    error: undefined,
    role_permissions: undefined
}

export const getUserGroupsAsync = createAsyncThunk(
    "getUserGroups",
    async (params, thunkAPI) => {
        try{
            let response = await getUserGroups(params)
            return response.data
        }
        catch(error){
            thunkAPI.rejectWithValue(error.response.data)
        }
    }
)
export const getUserGroupDetailAsync = createAsyncThunk(
    "getUserGroupDetail",
    async ({id, values}, thunkAPI) => {
        try{
            let response = await getUserGroupDetail({id, values})
            return response.data
        }
        catch(error){
            thunkAPI.rejectWithValue(error.response.data)
        }
    }
)
export const putUserGroupAsync = createAsyncThunk(
    "putUserGroups",
    async (data, thunkAPI) => {
        try{
            let response = await putUserGroup(data)
            return response.data
        }
        catch(error){
            thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const UserGroupSlice = createSlice({
    name: "UserGroupSlice",
    initialState,
    reducers: {
        setStatus: (state, action) => {
            let [key] = Object.keys(action.payload)
            state.status[key] = action.payload[key]
        },
        clearUserGroups: state => {
            state.list = undefined;
        },
        clearUserGroupDetail: state => {
            state.detail = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getUserGroupsAsync.pending, (state) => {
            state.status.list = 'loading';
        })
        .addCase(getUserGroupsAsync.fulfilled, (state, action) => {
            state.list = action.payload
            state.status.list = 'idle';
            state.error = undefined;
        })
        .addCase(getUserGroupsAsync.rejected, (state, action) => {
            state.status.list = 'idle';
            if(action?.error?.name !== "AbortError"){
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getUserGroups"
                }
            }
        })
        .addCase(getUserGroupDetailAsync.pending, (state) => {
            state.status.detail = 'loading';
        })
        .addCase(getUserGroupDetailAsync.fulfilled, (state, action) => {
            state.detail = action.payload
            if(action.meta.arg?.isPermission){
                state.role_permissions = action.payload
            }
            state.status.detail = 'idle';
            state.error = undefined;
        })
        .addCase(getUserGroupDetailAsync.rejected, (state, action) => {
            state.status.detail = 'idle';
            if(action?.error?.name !== "AbortError"){
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getUserGroupDetail"
                }
            }
        })
        .addCase(putUserGroupAsync.pending, (state) => {
            state.status.detail = 'updating';
        })
        .addCase(putUserGroupAsync.fulfilled, (state, action) => {
            state.detail = action.payload
            state.status.detail = 'idle';
            message.success("Cập nhật quyền thành công!")
            state.error = undefined;
        })
        .addCase(putUserGroupAsync.rejected, (state, action) => {
            state.status.detail = 'idle';
            if(action?.error?.name !== "AbortError"){
                message.error("Cập nhật dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "putUserGroup"
                }
            }
        })
        
    }
})

export const selectUserGroups = state => state.userGroups.list
export const selectUserGroupDetail = state => state.userGroups.detail
export const selectUserGroupPermission = state => state.userGroups.role_permissions
export const selectStatusList = state => state.userGroups.status.list
export const selectStatusDetail = state => state.userGroups.status.detail

export const {setStatus, clearUserGroupDetail, clearUserGroups} = UserGroupSlice.actions

export default UserGroupSlice.reducer