import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import {
  getProducts,
  getProductDetail,
  postProduct,
  putProduct,
  putProductStatus,
} from "./productAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getProductsAsync = createAsyncThunk(
  "getProducts",
  async (params, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });

    try {
      const response = await getProducts(params, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getProductDetailAsync = createAsyncThunk(
  "productDetail",
  async (data, thunkAPI) => {
    try {
      const response = await getProductDetail(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postProductAsync = createAsyncThunk(
  "postProduct",
  async (data, thunkAPI) => {
    try {
      const response = await postProduct(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putProductAsync = createAsyncThunk(
  "putProduct",
  async (data, thunkAPI) => {
    try {
      const response = await putProduct(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putProductStatusAsync = createAsyncThunk(
  "putProductStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putProduct(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearProductDetail: (state) => {
      state.detail = undefined;
    },
    clearProducts: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getProductsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProductsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getProductsAsync.rejected, (state, action) => {
        if (!action?.meta?.aborted) {
          state.status = "idle";
        }
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getProducts",
          };
        }
      })
      .addCase(getProductDetailAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(getProductDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getProductDetailAsync.rejected, (state, action) => {
        if (!action?.meta?.aborted) {
          state.status = "idle";
        }
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getProductDetail",
          };
        }
      })
      .addCase(postProductAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(postProductAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putProductAsync.pending, (state) => {
        state.status = "update_loading";
      })
      .addCase(putProductAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật thành công!");
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putProductAsync.rejected, (state, action) => {
        if (!action?.meta?.aborted) {
          state.status = "idle";
        }
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại!");
          state.error = {
            payload: action.payload,
            title: "putProduct",
          };
        }
      })
      .addCase(putProductStatusAsync.pending, (state) => {
        state.status = "update_status_loading";
      })
      .addCase(putProductStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putProductStatusAsync.rejected, (state, action) => {
        if (!action?.meta?.aborted) {
          state.status = "idle";
        }
        if (action?.error?.name !== "AbortError") {
          message.error("Thay đổi trạng thái thất bại!");
          state.error = {
            payload: action.payload,
            title: "putProductStatus",
          };
        }
      });
  },
});

export const selectProducts = (state) => state.products.list;
export const selectProductDetail = (state) => state.products.detail;
export const productsStatus = (state) => state.products.status;
export const productsError = (state) => state.products.error;

export const { clearProductDetail, clearProducts, setIdleStatus } =
  productSlice.actions;

export default productSlice.reducer;
