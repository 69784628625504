import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getBusinessReceipts = (params, option) => {
  return axiosClient.get("output-business-bills", {
    params,
    ...option,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getBusinessReceiptDetail = (id) => {
  return axiosClient.get("output-business-bills/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getLineNumberByPosition = (params) => {
  return axiosClient.get("output-business-bill-detail-positions", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const postBusinessReceiptPositions = ({ id, values }) => {
  return axiosClient.post(
    "output-business-bill-details/position/" + id,
    values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};
const getBusinessReceiptStatisticByStatus = (params) => {
  return axiosClient.get("output-business-bills/statistic/status", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putSuggestLineNumberPosition = ({ id, values }) => {
  return axiosClient.put(
    "output-business-bill-details/position/" + id,
    values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const postBusinessReceipt = (data) => {
  return axiosClient.post("output-business-bills", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putBusinessReceipt = (data) => {
  return axiosClient.put("output-business-bills/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putBusinessReceiptStatus = (data) => {
  return axiosClient.put(
    "output-business-bills-status/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const putBusinessReceiptPositionStatus = (data) => {
  return axiosClient.put(
    "output-business-bill-detail-positions-status",
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const postLineNumber = (data) => {
  return axiosClient.post("output-business-bill-details", data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putLineNumberQuantity = (data) => {
  return axiosClient.put(
    "output-business-bill-details/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};
const putLineNumberStatus = (data) => {
  return axiosClient.put(
    "output-business-bill-details-status/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};
const putLineNumberPosition = (data) => {
  return axiosClient.put(
    "output-business-bill-detail-positions/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};
const putLineNumberPositionStatus = (data) => {
  return axiosClient.put(
    "output-business-bill-detail-positions-status/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const putLineNumberPositionStatusReverse = (data) => {
  return axiosClient.put(
    "output-business-bill-detail-positions-status-reverse/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const postBusinessReceiptPosition = (data) => {
  return axiosClient.post(
    "output-business-bill-detail-positions",
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const putAllLineNumber = (data) => {
  return axiosClient.put("output-business-bill-details-list", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const countExportPrint = (id, data) => {
  return axiosClient.put("output-business-bills-print/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getBusinessReceipts,
  getBusinessReceiptDetail,
  getBusinessReceiptStatisticByStatus,
  postBusinessReceipt,
  putBusinessReceipt,
  putBusinessReceiptStatus,
  getLineNumberByPosition,
  postLineNumber,
  putLineNumberQuantity,
  putLineNumberStatus,
  putLineNumberPosition,
  putLineNumberPositionStatus,
  putSuggestLineNumberPosition,
  postBusinessReceiptPosition,
  putLineNumberPositionStatusReverse,
  postBusinessReceiptPositions,
  putBusinessReceiptPositionStatus,
  putAllLineNumber,
  countExportPrint,
};
