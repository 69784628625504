const statuses = [
    {
        id: 1,
        key: "1",
        label: "Hoạt động",
        color: "#00C853",
        actionLabel: "Hoạt động",
        nextAction: [-1]
    },
    {
        id: -1,
        key: "-1",
        label: "Tạm ngưng",
        color: "#FFAB00",
        actionLabel: "Tạm ngưng",
        nextAction: [1],
    },
    // {
    //     id: -2,
    //     key: "-2",
    //     label: "Đã khóa",
    //     color: "#FF3D00",
    //     actionLabel: "Khóa",
    //     nextAction: [1, -1],
    // },
];

const types = [
    {
        id: 1,
        key: "1",
        label: "Kho sản xuất",
        color: "#0078D7",
    },
    {
        id: 2,
        key: "2",
        label: "Kho bán",
        color: "#018574",
    },
]

const findStatus = (status_id, field) => {
    let status = statuses.find(s => s.id * 1 === status_id * 1);
    return status
        ? field && status?.[field]
            ? status[field]
            : status
        : undefined;
}

const filterStatus = ({ array_ids = [], currentStatus = undefined }) => {
    if (currentStatus) {
        let current = findStatus(currentStatus)
        if (current?.nextAction) {
            return current.nextAction.map(item => findStatus(item))
        }
        return
    }
    return array_ids.map(item => findStatus(item))
}

const findType = (type_id, field) => {
    let type = types.find(s => s.id * 1 === type_id * 1);
    return type
        ? field && type?.[field]
            ? type[field]
            : type
        : undefined;
}

const filterType = ({ array_ids = [], currentStatus = undefined }) => {
    if (currentStatus) {
        let current = findType(currentStatus)
        if (current?.nextAction) {
            return current.nextAction.map(item => findType(item))
        }
        return
    }
    return array_ids.map(item => findType(item))
}

export {
    statuses,
    findStatus,
    filterStatus,
    types,
    findType,
    filterType
}