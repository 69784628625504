import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getWarehouses = (params) => {
    return axiosClient.get("warehouses", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getWarehouseDetail = (id) => {
    return axiosClient.get("warehouses/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postWarehouse = (data) => {
    return axiosClient.post("warehouses", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putWarehouse = (data) => {
    return axiosClient.put("warehouses/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

// const putWarehouseStatus = (id, data) => {
//     return axiosClient.put("suppliers-status/" + id, data, {
//         headers: {
//             Authorization: "Bearer " + getToken()
//         }
//     })
// }

export {
    getWarehouses,
    getWarehouseDetail,
    postWarehouse,
    putWarehouse,
    // putWarehouseStatus
}