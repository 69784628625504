import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getTransporters = (params) => {
    return axiosClient.get("deliveries", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getTransporterDetail = (id) => {
    return axiosClient.get("deliveries/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postTransporter = (data) => {
    return axiosClient.post("deliveries", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putTransporter = (data) => {
    return axiosClient.put("deliveries/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

// const putTransporterStatus = (id, data) => {
//     return axiosClient.put("deliveries-status/" + id, data, {
//         headers: {
//             Authorization: "Bearer " + getToken()
//         }
//     })
// }

export {
    getTransporters,
    getTransporterDetail,
    postTransporter,
    putTransporter,
    // putTransporterStatus
}