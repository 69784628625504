import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import _ from "lodash";
import { statuses } from "../../common/Warehouses";
import {
  getWarehouses,
  getWarehouseDetail,
  postWarehouse,
  putWarehouse,
  putWarehouseStatus,
} from "./warehouseAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  current_warehouse: localStorage.warehouse_id * 1,
  activeList: undefined,
  status: "idle",
  error: undefined,
};

export const getWarehousesAsync = createAsyncThunk(
  "warehouses",
  async (params, thunkAPI) => {
    try {
      const response = await getWarehouses(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getWarehouseDetailAsync = createAsyncThunk(
  "warehouseDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getWarehouseDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postWarehouseAsync = createAsyncThunk(
  "postWarehouse",
  async (data, thunkAPI) => {
    try {
      const response = await postWarehouse(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putWarehouseAsync = createAsyncThunk(
  "putWarehouse",
  async (data, thunkAPI) => {
    try {
      const response = await putWarehouse(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putWarehouseStatusAsync = createAsyncThunk(
  "putWarehouseStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putWarehouse(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const warehouseSlice = createSlice({
  name: "warehouses",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearWarehouseDetail: (state) => {
      state.detail = undefined;
    },
    clearWarehouses: (state) => {
      state.list = undefined;
    },
    changeWarehouse: (state, action) => {
      state.current_warehouse = action.payload * 1;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getWarehousesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getWarehousesAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.activeList = action.payload.data.filter(
          (item) => item.status * 1 === statuses[0].id * 1
        );

        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getWarehousesAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWarehouses",
          };
        }
      })
      .addCase(getWarehouseDetailAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(getWarehouseDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getWarehouseDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWarehouseDetail",
          };
        }
      })
      .addCase(postWarehouseAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(postWarehouseAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putWarehouseAsync.pending, (state) => {
        state.status = "update_loading";
      })
      .addCase(putWarehouseAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.activeList = updateWarehouse(state.activeList, action.payload);
        message.success("Cập nhật thành công!");
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putWarehouseAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại!");
          state.error = {
            payload: action.payload,
            title: "putWarehouse",
          };
        }
      })
      .addCase(putWarehouseStatusAsync.pending, (state) => {
        state.status = "update_status_loading";
      })
      .addCase(putWarehouseStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putWarehouseStatusAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Thay đổi trạng thái thất bại!");
          state.error = {
            payload: action.payload,
            title: "putWarehouseStatus",
          };
        }
      });
  },
});

const updateWarehouse = (_data, changedValue) => {
  let index = _data.findIndex((i) => i.id === changedValue.id);
  _data[index] = { ...changedValue };
  return _data;
};

export const selectWarehouses = (state) => state.warehouses.list;
export const selectActiveWarehouses = (state) => state.warehouses.activeList;
export const selectCurrentWarehouses = (state) =>
  state.warehouses.current_warehouse;
export const selectWarehouseDetail = (state) => state.warehouses.detail;
export const warehousesStatus = (state) => state.warehouses.status;
export const warehousesError = (state) => state.warehouses.error;
export const selectWarehouseName = (state) => {
  let current_warehouse = state.warehouses?.current_warehouse ?? 0;
  let _warehouse = state.warehouses?.activeList
    ? state.warehouses.activeList.find((w) => w.id === current_warehouse * 1)
    : undefined;
  let warehouse_name = _warehouse?.name
    ? _warehouse.name.split(" ").join("_")
    : "";
  return warehouse_name;
};

export const {
  clearWarehouseDetail,
  clearWarehouses,
  setIdleStatus,
  changeWarehouse,
} = warehouseSlice.actions;

export default warehouseSlice.reducer;
