import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getEditInventories = (params) => {
    return axiosClient.get("adjustment-bills", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getEditInventoryDetail = (id) => {
    return axiosClient.get("adjustment-bills/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postEditInventory = (data) => {
    return axiosClient.post("adjustment-bills", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putEditInventory = (data) => {
    return axiosClient.put("adjustment-bills/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

// const putWarehouseStatus = (id, data) => {
//     return axiosClient.put("suppliers-status/" + id, data, {
//         headers: {
//             Authorization: "Bearer " + getToken()
//         }
//     })
// }

export {
    getEditInventories,
    getEditInventoryDetail,
    postEditInventory,
    putEditInventory,
    // putWarehouseStatus
}