import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getZones = (params) => {
    return axiosClient.get("zones", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getZoneDetail = (id) => {
    return axiosClient.get("zones/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postZone = (data) => {
    return axiosClient.post("zones", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putZone = (data) => {
    return axiosClient.put("zones/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getZonesAvailable = (params) => {
    return axiosClient.get("zones-available", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getZones,
    getZoneDetail,
    postZone,
    putZone,
    getZonesAvailable
}