import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { message } from "antd";
import { getExportReceipts } from "features/exportReceipt/exportReceiptAPI";
import { putExportReceiptAsync } from "features/exportReceipt/exportReceiptSlice";
import { moveInventory } from "features/inventory/inventoryAPI";
import _ from "lodash";
import { getAvailableLocations, getLocations } from "../location/locationAPI";
import { getSuppliers } from "../supplier/supplierAPI";
import { getWarehouses } from "../warehouse/warehouseAPI";
import {
  getImportReceipts,
  getImportReceiptDetail,
  postImportReceipt,
  putImportReceipt,
  getLineNumberByPosition,
  postSuggestLineNumberPosition,
  getImportReceiptStatisticByStatus,
  putLineNumberPositionStatus,
  postLineNumberPosition,
  putImportReceiptStatusReverse,
  putLineNumberPosition,
  putLineNumberPositionMove,
  putImportReceiptStatus,
} from "./importReceiptAPI";

import axios from "axios";

const initialState = {
  list: undefined,
  detail: undefined,
  status: {},
  error: undefined,
  warehouses: undefined,
  users: undefined,
  suppliers: undefined,
  line_numbers: undefined,
  statistic: undefined,
  locations: undefined,
};

export const getImportReceiptsAsync = createAsyncThunk(
  "getImportReceipts",
  async (params, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await getImportReceipts(params, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getImportReceiptDetailAsync = createAsyncThunk(
  "getImportReceiptDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getImportReceiptDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getLineNumberByPositionAsync = createAsyncThunk(
  "getLineNumberByPosition",
  async (data, thunkAPI) => {
    try {
      const response = await getLineNumberByPosition(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getImportReceiptStatisticByStatusAsync = createAsyncThunk(
  "getImportReceiptStatisticByStatus",
  async (data, thunkAPI) => {
    try {
      const response = await getImportReceiptStatisticByStatus(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getLocationsAsync = createAsyncThunk(
  "getLocations",
  async (data, thunkAPI) => {
    try {
      const response = await getAvailableLocations(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postSuggestLineNumberPositionAsync = createAsyncThunk(
  "postSuggestLineNumberPosition",
  async (data, thunkAPI) => {
    try {
      const response = await postSuggestLineNumberPosition(data);
      // The value we return becomes the `fulfilled` action payload
      thunkAPI.dispatch(
        getImportReceiptDetailAsync(response.data.input_bill_id)
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postImportReceiptAsync = createAsyncThunk(
  "postImportReceipt",
  async (data, thunkAPI) => {
    try {
      const response = await postImportReceipt(data);
      if (data.warehouse_id_from) {
        let _data = {
          id: data.output_bill_id,
          values: {
            po_number: response.data.po_number,
          },
        };
        thunkAPI.dispatch(putExportReceiptAsync(_data));
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postImportReceiptPositionAsync = createAsyncThunk(
  "postImportReceiptPosition",
  async (data, thunkAPI) => {
    try {
      const response = await postLineNumberPosition(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putImportReceiptAsync = createAsyncThunk(
  "putImportReceipt",
  async (data, thunkAPI) => {
    try {
      const response = await putImportReceipt(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putImportReceiptStatusAsync = createAsyncThunk(
  "putImportReceiptStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putImportReceiptStatus(data);
      // let {so_number, status} = response.data
      // if(so_number && status === -1){
      //     let {data} = await getExportReceipts({so_number})
      //     let output_bill = data.data[0] || undefined
      //     if(output_bill?.id){
      //         let _data = {
      //             id: output_bill.id,
      //             values: {
      //                 po_number: ""
      //             }
      //         }
      //         thunkAPI.dispatch(putExportReceiptAsync(_data))
      //     }
      // }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const putLineNumberPositionStatusAsync = createAsyncThunk(
  "putLineNumberPositionStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putLineNumberPositionStatus(data);
      let { importReceipts } = thunkAPI.getState();
      let { id } = importReceipts.detail;
      thunkAPI.dispatch(getImportReceiptDetailAsync(id));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const putLineNumberPositionAsync = createAsyncThunk(
  "putLineNumberPositionAsync",
  async (data, thunkAPI) => {
    try {
      const response = await putLineNumberPosition(data);
      let { importReceipts } = thunkAPI.getState();
      let { id } = importReceipts.detail;
      thunkAPI.dispatch(getImportReceiptDetailAsync(id));
      thunkAPI.dispatch(setStatus({ position: "idle" }));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setStatus({ position: "idle" }));
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const putLineNumberPositionMoveAsync = createAsyncThunk(
  "putLineNumberPositionMoveAsync",
  async (data, thunkAPI) => {
    try {
      const response = await putLineNumberPositionMove(data);
      let { importReceipts } = thunkAPI.getState();
      let { id } = importReceipts.detail;
      thunkAPI.dispatch(getImportReceiptDetailAsync(id));
      thunkAPI.dispatch(setStatus({ position: "idle" }));
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(setStatus({ position: "idle" }));
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getWarehousesAsync = createAsyncThunk(
  "warehouses",
  async (params, thunkAPI) => {
    try {
      const response = await getWarehouses(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putImportReceiptStatusReverseAsync = createAsyncThunk(
  "reverseImportReceiptPositionStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putImportReceiptStatusReverse(data);
      let { importReceipts } = thunkAPI.getState();
      let { id } = importReceipts.detail;
      thunkAPI.dispatch(getImportReceiptDetailAsync(id));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const moveInventoryAsync = createAsyncThunk(
  "importReceipts/inventoryMove",
  async (params, thunkAPI) => {
    try {
      const response = await moveInventory(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const importReceiptSlice = createSlice({
  name: "importReceipts",
  initialState,
  reducers: {
    setIdleStatus: (state, action) => {
      let [key] = Object.keys(action.payload);
      state.status[key] = action.payload[key];
    },
    setStatus: (state, action) => {
      let [key] = Object.keys(action.payload);
      state.status[key] = action.payload[key];
    },
    updateImportReceipt: (state, action) => {
      state.detail = action.payload;
    },
    clearImportReceiptDetail: (state) => {
      state.detail = undefined;
    },
    clearImportReceipts: (state) => {
      state.list = undefined;
    },
    clearImportReceiptsStatistic: (state) => {
      state.statistic = undefined;
    },
    setInitImportReceipt: (state) => {
      state.list = undefined;
      state.detail = undefined;
      state.status = {};
      state.error = undefined;
      state.warehouses = undefined;
      state.users = undefined;
      state.suppliers = undefined;
      state.line_numbers = undefined;
      state.locations = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getImportReceiptsAsync.pending, (state) => {
        state.status.list = "loading";
      })
      .addCase(getImportReceiptsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status.list = "idle";
        state.error = undefined;
      })
      .addCase(getImportReceiptsAsync.rejected, (state, action) => {
        if (action?.error?.name !== "AbortError") {
          state.status.list = "idle";
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getImportReceipts",
          };
        }
      })
      .addCase(getLocationsAsync.pending, (state) => {
        state.status.locations = "loading";
      })
      .addCase(getLocationsAsync.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.status.locations = "idle";
        state.error = undefined;
      })
      .addCase(getLocationsAsync.rejected, (state, action) => {
        state.status.locations = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getLocations",
          };
        }
      })
      .addCase(getImportReceiptDetailAsync.pending, (state) => {
        state.status.detail = "loading";
      })
      .addCase(getImportReceiptDetailAsync.fulfilled, (state, action) => {
        action.payload.line_numbers = action.payload.line_numbers.filter(
          (line) => line.status !== -1
        );
        state.detail = action.payload;
        state.status.detail = "idle";
        state.error = undefined;
      })
      .addCase(getImportReceiptDetailAsync.rejected, (state, action) => {
        if (state.status?.detail) {
          state.status.detail = "idle";
        }
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getImportReceiptDetail",
          };
        }
      })
      .addCase(getLineNumberByPositionAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(getLineNumberByPositionAsync.fulfilled, (state, action) => {
        state.line_numbers = action.payload.filter(
          (line) => line.status !== -1
        );
        state.status.position = "idle";
        state.error = undefined;
      })
      .addCase(getLineNumberByPositionAsync.rejected, (state, action) => {
        state.status.position = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getLineNumbers",
          };
        }
      })
      .addCase(getImportReceiptStatisticByStatusAsync.pending, (state) => {
        state.status.statistic = "loading";
      })
      .addCase(
        getImportReceiptStatisticByStatusAsync.fulfilled,
        (state, action) => {
          state.statistic = action.payload;
          state.status.statistic = "idle";
          state.error = undefined;
        }
      )
      .addCase(
        getImportReceiptStatisticByStatusAsync.rejected,
        (state, action) => {
          if (state.status?.statistic) {
            state.status.statistic = "idle";
          }
          if (action?.error?.name !== "AbortError") {
            message.error("Lấy dữ liệu thất bại!");
            state.error = {
              payload: action.payload,
              title: "getStatistic",
            };
          }
        }
      )
      .addCase(postSuggestLineNumberPositionAsync.pending, (state) => {
        state.status.line = "loading";
      })
      .addCase(
        postSuggestLineNumberPositionAsync.fulfilled,
        (state, action) => {
          let index = state.detail.line_numbers.findIndex(
            (line) => line.id === action.payload.id
          );
          state.detail.line_numbers[index] = action.payload;
          state.detail.status = 2;
          state.status.line = "idle";
          state.error = undefined;
        }
      )
      .addCase(postSuggestLineNumberPositionAsync.rejected, (state, action) => {
        state.status.line = "idle";
        if (action?.error?.name !== "AbortError") {
          const { field } = action?.payload?.[0] || {};
          if (field === "get_position_available") {
            message.error("Không có vị trí đủ sức chứa!");
          } else if (field === "Position not enough available") {
            message.error("Không có vị trí đủ sức chứa!");
          } else {
            message.error("Lấy dữ liệu thất bại!");
          }
          state.error = {
            payload: action.payload,
            title: "suggestLineNumbers",
          };
        }
      })
      .addCase(postImportReceiptPositionAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(postImportReceiptPositionAsync.fulfilled, (state, action) => {
        let index = state.detail.line_numbers.findIndex(
          (line) => line.id === action.payload.input_bill_detail_id
        );
        state.detail.line_numbers[index].positions.push(action.payload);
        state.status.position = "idle";
        state.error = undefined;
      })
      .addCase(postImportReceiptPositionAsync.rejected, (state, action) => {
        state.status.position = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "postLineNumberPosition",
          };
        }
      })
      .addCase(postImportReceiptAsync.pending, (state) => {
        state.status.create = "loading";
      })
      .addCase(postImportReceiptAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status.create = "create_done";
        state.error = undefined;
      })
      .addCase(postImportReceiptAsync.rejected, (state, action) => {
        state.status.create = "idle";
        if (action?.error?.name !== "AbortError") {
          let { field } = action?.payload?.[0] || {};
          if (field === "user don't have brv_code") {
            message.error("Tạo thất bại! Nhân viên chưa kích hoạt mã Bravo.");
          } else {
            message.error("Tạo thất bại!");
          }
          state.error = {
            payload: action.payload,
            title: "postImportReceipt",
          };
        }
      })
      .addCase(putImportReceiptAsync.pending, (state) => {
        state.status.detail = "loading";
      })
      .addCase(putImportReceiptAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật thành công!");
        state.status.detail = "done";
        state.error = undefined;
      })
      .addCase(putImportReceiptAsync.rejected, (state, action) => {
        state.status.detail = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại!");
          state.error = {
            payload: action.payload,
            title: "putImportReceipt",
          };
        }
      })
      .addCase(putImportReceiptStatusAsync.pending, (state) => {
        state.status.detail = "loading";
      })
      .addCase(putImportReceiptStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        console.log(state, action);
        state.status.detail = "done";
        state.error = undefined;
        if (action?.meta?.arg?.values?.status === -1) {
          message.success("Hủy đơn nhập thành công!");
        } else {
          message.success("Cập nhật đơn nhập thành công!");
        }
      })
      .addCase(putImportReceiptStatusAsync.rejected, (state, action) => {
        state.status.detail = "idle";
        const { status, data } = action.payload;
        const { message: msg, field } = data[0];
        if (status === 403) {
          if (msg === "Have transaction") {
            message.error(
              "Cập nhật trạng thái thất bại! Đã có tác động đến đơn nhập!"
            );
          }
          if (msg === "was approved by Bravo") {
            message.error(
              "Cập nhật trạng thái thất bại! Đã xác nhận trên hệ thống bravo!"
            );
          }
        }
        if (status === 404) {
          if (field === "id") {
            message.error("Đơn nhập không tồn tại!");
          }
        }
        if (status === 409) {
          if (field === "status") {
            message.error("Cập nhật trùng trạng thái!");
          }
          if (field === "output bill was completed") {
            message.error("Đã phát sinh đơn xuất giao hàng thành công!");
          }
        }
        if (status === 500) {
          if (field === "Can not post Bravo : Chứng từ đã được kế toán duyệt")
            message.error(field);
        }
      })
      .addCase(putLineNumberPositionStatusAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(putLineNumberPositionStatusAsync.fulfilled, (state, action) => {
        // let line_index = state.detail.line_numbers.findIndex(line => line.id === action.payload.input_bill_detail_id)
        // let pos_index = state.detail.line_numbers[line_index].positions.findIndex(pos => pos.id === action.payload.id)
        // state.detail.line_numbers[line_index].positions[pos_index] = action.payload
        // if(_.every(state.detail.line_numbers[line_index].positions, p => p.status === 3)){
        //     state.detail.line_numbers[line_index].status = 3
        // }
        // if(_.every(state.detail.line_numbers, line => line.status === 3)){
        //     state.detail.status = 3
        // }
        state.status.position = "done";
        state.error = undefined;
      })
      .addCase(putLineNumberPositionStatusAsync.rejected, (state, action) => {
        state.status.position = "idle";
        // if (action?.error?.name !== "AbortError") {
        //     message.error("Thay đổi trạng thái thất bại!")
        //     state.error = {
        //         payload: action.payload,
        //         title: "putPositionStatus"
        //     }
        // }
        const { arg } = action.meta;
        const { status, data } = action.payload;
        const { message: msg, field } = data[0];
        if (status === 403) {
          if (msg === "Have transaction") {
            message.error(
              "Cập nhật trạng thái thất bại! Đã có tác động đến đơn nhập!"
            );
          }
        }
        if (status === 404) {
          if (field === "id") {
            message.error("Line number không tồn tại!");
          }
          if (field === "input_bill_detail") {
            message.error("Đơn nhập không tồn tại!");
          }
          if (field.includes("ids ")) {
            message.error(`Line number không tồn tại!`);
          }
        }
        if (status === 409) {
          if (field === "status") {
            message.error("Cập nhật trùng trạng thái!");
          }
        }
      })
      .addCase(putLineNumberPositionAsync.pending, (state) => {
        state.status.line = "loading";
      })
      .addCase(putLineNumberPositionAsync.fulfilled, (state, action) => {
        state.status.line = "update_position_done";
        // let line_index = state.detail.line_numbers.findIndex(line => line.id === action.payload.input_bill_detail_id && line.uom_id === action.payload.detail_info.uom_id)
        // let pos_index = state.detail.line_numbers[line_index].positions.findIndex(pos => pos.id === action.payload.id)
        // state.detail.line_numbers[line_index].positions[pos_index] = action.payload
        message.success(
          `Cập nhật vị trí ${action.payload.position_info.name} thành công!`
        );
        state.error = undefined;
      })
      .addCase(putLineNumberPositionAsync.rejected, (state, action) => {
        state.status.line = "idle";
        // if (action?.error?.name !== "AbortError") {
        //     message.error("Thay đổi thông tin thất bại!")
        //     state.error = {
        //         payload: action.payload,
        //         title: "putPosition"
        //     }
        // }
        const { status, data } = action.payload;
        const { message: msg, field } = data[0];
        if (status === 404) {
          if (field === "id") {
            message.error("Line number không tồn tại!");
          }
          if (field === "position_id") {
            message.error("Vị trí không tồn tại!");
          }
        }
      })
      .addCase(putLineNumberPositionMoveAsync.pending, (state) => {
        state.status.line = "loading";
      })
      .addCase(putLineNumberPositionMoveAsync.fulfilled, (state, action) => {
        state.status.line = "move_position_done";
        message.success(`Thêm vị trí thành công!`);
        state.error = undefined;
      })
      .addCase(putLineNumberPositionMoveAsync.rejected, (state, action) => {
        state.status.line = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Thay đổi thông tin thất bại!");
          state.error = {
            payload: action.payload,
            title: "movePosition",
          };
        }
      })
      .addCase(putImportReceiptStatusReverseAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(
        putImportReceiptStatusReverseAsync.fulfilled,
        (state, action) => {
          // let line_index = state.detail.line_numbers.findIndex(line => line.id === action.payload.input_bill_detail_id)
          // let pos_index = state.detail.line_numbers[line_index].positions.findIndex(pos => pos.id === action.payload.id)
          // state.detail.line_numbers[line_index].positions[pos_index] = action.payload
          // if(_.every(state.detail.line_numbers[line_index].positions, p => p.status === 3)){
          //     state.detail.line_numbers[line_index].status = 3
          // }
          // if(_.every(state.detail.line_numbers, line => line.status === 3)){
          //     state.detail.status = 3
          // }
          state.status.position = "done";
          state.error = undefined;
        }
      )
      .addCase(putImportReceiptStatusReverseAsync.rejected, (state, action) => {
        state.status.position = "idle";
        // if (action?.error?.name !== "AbortError") {
        //     const { code, message } = action?.payload?.[0] || {}
        //     if (code === "Permission" && message.includes("Cant reverse")) {
        //         message.error("Trả lại thất bại! Đã được sử dụng để xuất hàng!")
        //         return
        //     }
        //     message.error("Thay đổi trạng thái thất bại!")
        //     state.error = {
        //         payload: action.payload,
        //         title: "putPositionStatusReverse"
        //     }
        // }
        const { arg } = action.meta;
        const { status, data } = action.payload;
        const { message: msg, field } = data[0];
        if (status === 403) {
          if (msg === "Cant reverse " + arg.id) {
            message.error(
              "Không thể cập nhật trạng thái! Đã phát sinh đơn xuất!"
            );
          }
        }
        if (status === 404) {
          if (field === "id") {
            message.error("Line number không tồn tại!");
          }
          if (field === "bill_detail_id") {
            message.error("Đơn nhập không tồn tại!");
          }
        }
        if (status === 409) {
          if (field === "status") {
            message.error("Cập nhật trùng trạng thái!");
          }
        }
      })
      .addCase(getWarehousesAsync.pending, (state) => {
        state.status.warehouse = "loading";
      })
      .addCase(getWarehousesAsync.fulfilled, (state, action) => {
        state.warehouses = action.payload.data;
        state.status.warehouse = "idle";
        state.error = undefined;
      })
      .addCase(getWarehousesAsync.rejected, (state, action) => {
        state.status.warehouse = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWarehouses",
          };
        }
      })
      .addCase(moveInventoryAsync.pending, (state) => {
        state.status.postion = "update_loading";
      })
      .addCase(moveInventoryAsync.fulfilled, (state, action) => {
        message.success("Di chuyển vị trí thành công!");
        state.status.postion = "move_done";
        state.error = undefined;
      })
      .addCase(moveInventoryAsync.rejected, (state, action) => {
        state.status.postion = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Di chuyển vị trí thất bại!");
          state.error = {
            payload: action.payload,
            title: "inventoryMove",
          };
        }
      });
  },
});

export const selectImportReceipts = (state) => state.importReceipts.list;
export const selectImportReceiptDetail = (state) => state.importReceipts.detail;
export const importReceiptsStatus = (state) => state.importReceipts.status;
export const importReceiptsError = (state) => state.importReceipts.error;
export const selectWarehouses = (state) => state.importReceipts.warehouses;
export const selectStatistic = (state) => state.importReceipts.statistic;
export const selectLocations = (state) => state.importReceipts.locations;

export const {
  clearImportReceiptDetail,
  clearImportReceipts,
  setIdleStatus,
  updateImportReceipt,
  setStatus,
  setInitImportReceipt,
  clearImportReceiptsStatistic,
} = importReceiptSlice.actions;

export default importReceiptSlice.reducer;
