import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getTransporters,
    getTransporterDetail,
    postTransporter,
    putTransporter,
} from './transporterAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
};

export const getTransportersAsync = createAsyncThunk(
    'transporters',
    async (params, thunkAPI) => {
        try {
            const response = await getTransporters(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getTransporterDetailAsync = createAsyncThunk(
    'transporterDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getTransporterDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const postTransporterAsync = createAsyncThunk(
    'postTransporter',
    async (data, thunkAPI) => {
        try {
            const response = await postTransporter(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putTransporterAsync = createAsyncThunk(
    'putTransporter',
    async (data, thunkAPI) => {
        try {
            const response = await putTransporter(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putTransporterStatusAsync = createAsyncThunk(
    'putTransporterStatus',
    async (data, thunkAPI) => {
        try {
            const response = await putTransporter(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const transporterSlice = createSlice({
    name: 'transporters',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearTransporterDetail: state => {
            state.detail = undefined;
        },
        clearTransporters: state => {
            state.list = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getTransportersAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getTransportersAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getTransportersAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getTransporters"
                    }
                }
            })
            .addCase(getTransporterDetailAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(getTransporterDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getTransporterDetailAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getTransporterDetail"
                    }
                }
            })
            .addCase(postTransporterAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(postTransporterAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putTransporterAsync.pending, (state) => {
                state.status = 'update_loading';
            })
            .addCase(putTransporterAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                message.success("Cập nhật thành công!")
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putTransporterAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Cập nhật thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putTransporter"
                    }
                }
            })
            .addCase(putTransporterStatusAsync.pending, (state) => {
                state.status = 'update_status_loading';
            })
            .addCase(putTransporterStatusAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putTransporterStatusAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Thay đổi trạng thái thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putTransporterStatus"
                    }
                }
            })
    },
});

export const selectTransporters = state => state.transporters.list
export const selectTransporterDetail = state => state.transporters.detail
export const transportersStatus = state => state.transporters.status
export const transportersError = state => state.transporters.error

export const { clearTransporterDetail, clearTransporters, setIdleStatus } = transporterSlice.actions;

export default transporterSlice.reducer;

