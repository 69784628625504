import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getBillQuantityByStatus = (params, options) => {
    return axiosClient.get("dashboard/bill-status-io", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getBillProductQuantity = (params, options) => {
    return axiosClient.get("dashboard/quantity-io", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getBillQuantityByType = (params, options) => {
    return axiosClient.get("dashboard/bill-type-io", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getBillQuantityByStatus,
    getBillProductQuantity,
    getBillQuantityByType
}