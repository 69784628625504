import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { message } from "antd";
import { getLocations } from "../location/locationAPI";
import { getSuppliers } from "../supplier/supplierAPI";
import { getWarehouses } from "../warehouse/warehouseAPI";
import {
  getExportReceipts,
  getExportReceiptDetail,
  postExportReceipt,
  putExportReceipt,
  getLineNumberByPosition,
  putSuggestLineNumberPosition,
  getExportReceiptStatisticByStatus,
  putLineNumberPositionStatus,
  putExportReceiptStatus,
  postExportReceiptPosition,
  putLineNumberPositionStatusReverse,
  postExportReceiptPositions,
  putExportReceiptPositionStatus,
  postLineNumber,
} from "./exportReceiptAPI";
import _ from "lodash";
import { postImportReceiptAsync } from "features/importReceipt/importReceiptSlice";
import moment from "moment";
import { getAvailablePosition } from "features/importReceipt/importReceiptAPI";
import axios from "axios";

const initialState = {
  list: undefined,
  detail: undefined,
  status: {},
  error: undefined,
  warehouses: undefined,
  users: undefined,
  suppliers: undefined,
  line_numbers: undefined,
  statistic: undefined,
  locations: undefined,
  inventoryError: undefined,
};

export const getExportReceiptsAsync = createAsyncThunk(
  "getExportReceipts",
  async (params, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await getExportReceipts(params, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getExportReceiptDetailAsync = createAsyncThunk(
  "getExportReceiptDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getExportReceiptDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getLineNumberByPositionAsync = createAsyncThunk(
  "getExportLineNumberByPosition",
  async (data, thunkAPI) => {
    try {
      const response = await getLineNumberByPosition(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postExportReceiptPositionsAsync = createAsyncThunk(
  "postExportPositions",
  async (data, thunkAPI) => {
    try {
      const response = await postExportReceiptPositions(data);
      let { exportReceipts } = thunkAPI.getState();
      let { id } = exportReceipts.detail;
      thunkAPI.dispatch(getExportReceiptDetailAsync(id));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const getExportReceiptStatisticByStatusAsync = createAsyncThunk(
  "getExportReceiptStatisticByStatus",
  async (data, thunkAPI) => {
    try {
      const response = await getExportReceiptStatisticByStatus(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getLocationsAsync = createAsyncThunk(
  "getExportLocations",
  async (data, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      // const response = await getLocations(data);
      const response = await getAvailablePosition(data, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getSuppliersAsync = createAsyncThunk(
  "getExportSuppliers",
  async (data, thunkAPI) => {
    try {
      const response = await getSuppliers(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putSuggestLineNumberPositionAsync = createAsyncThunk(
  "putExportSuggestLineNumberPosition",
  async (data, thunkAPI) => {
    try {
      const response = await putSuggestLineNumberPosition(data);
      let { exportReceipts } = thunkAPI.getState();
      let { id } = exportReceipts.detail;
      thunkAPI.dispatch(getExportReceiptDetailAsync(id));
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const postExportReceiptAsync = createAsyncThunk(
  "postExportReceipt",
  async (data, thunkAPI) => {
    try {
      const response = await postExportReceipt(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putExportReceiptAsync = createAsyncThunk(
  "putExportReceipt",
  async (data, thunkAPI) => {
    try {
      const response = await putExportReceipt(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putExportReceiptStatusAsync = createAsyncThunk(
  "putExportReceiptStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putExportReceiptStatus(data);
      let { exportReceipts } = thunkAPI.getState();
      let { id } = exportReceipts.detail;
      thunkAPI.dispatch(getExportReceiptDetailAsync(id));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const putExportReceiptPositionStatusAsync = createAsyncThunk(
  "putExportReceiptPositionStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putExportReceiptPositionStatus(data);
      let { exportReceipts } = thunkAPI.getState();
      if (response?.data?.[0] && exportReceipts?.detail?.type === 3) {
        if (data.values.status === 4) {
          let { exportReceipts } = thunkAPI.getState();
          let {
            positions_line_number_info,
            warehouse_id,
            so_number,
            warehouse_id_to,
            id,
            customer_id,
          } = exportReceipts.detail;
          const { list_ids } = data.values;
          let products = list_ids.reduce((result, _id) => {
            let pos = positions_line_number_info.data.find(
              (pos) => pos.position_id * 1 === _id * 1
            );
            if (pos.id) {
              result.push({
                product_id: pos?.id,
                expect_quantity: pos?.position_info.quantity,
                quantity: pos?.position_info.quantity,
                product_detail_id: pos.product_detail_id,
                uom_id: pos.uom_id,
                mfg: pos?.position_info?.input_line_number?.mfg
                  ? moment(pos.position_info.input_line_number.mfg).format(
                      "YYYY-MM-DD"
                    )
                  : undefined,
                batch_lot:
                  pos?.position_info?.input_line_number?.batch_lot || undefined,
                exp: pos?.position_info?.input_line_number?.exp
                  ? moment(pos.position_info.input_line_number.exp).format(
                      "YYYY-MM-DD"
                    )
                  : undefined,
              });
            }
            return result;
          }, []);
          let _data = {
            type: 5,
            output_bill_id: id,
            warehouse_id_from: warehouse_id,
            warehouse_id: warehouse_id_to,
            products,
            input_date: moment().format("YYYY-MM-DD"),
            so_number,
          };

          if (customer_id) _data.customer_id = customer_id;

          thunkAPI.dispatch(postImportReceiptAsync(_data));
        }
      }
      let { id } = exportReceipts.detail;
      thunkAPI.dispatch(getExportReceiptDetailAsync(id));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);
export const putLineNumberPositionStatusAsync = createAsyncThunk(
  "putExportLineNumberPositionStatus",
  async (data, thunkAPI) => {
    try {
      const response = await putLineNumberPositionStatus(data);
      let { exportReceipts } = thunkAPI.getState();
      let { id } = exportReceipts.detail;
      thunkAPI.dispatch(getExportReceiptDetailAsync(id));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postExportReceiptPositionAsync = createAsyncThunk(
  "postExportReceiptPosition",
  async (data, thunkAPI) => {
    try {
      const response = await postExportReceiptPosition(data);
      let { exportReceipts } = thunkAPI.getState();
      let { id } = exportReceipts.detail;
      thunkAPI.dispatch(getExportReceiptDetailAsync(id));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const postLineNumberAsync = createAsyncThunk(
  "postLineNumber",
  async (data, thunkAPI) => {
    try {
      const response = await postLineNumber(data);
      let { exportReceipts } = thunkAPI.getState();
      let { id } = exportReceipts.detail;
      thunkAPI.dispatch(getExportReceiptDetailAsync(id));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putLineNumberPositionStatusReverseAsync = createAsyncThunk(
  "putLineNumberPositionStatusReverse",
  async (data, thunkAPI) => {
    try {
      const response = await putLineNumberPositionStatusReverse(data);
      let { exportReceipts } = thunkAPI.getState();
      let { id } = exportReceipts.detail;
      thunkAPI.dispatch(getExportReceiptDetailAsync(id));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

export const getWarehousesAsync = createAsyncThunk(
  "exportWarehouses",
  async (params, thunkAPI) => {
    try {
      const response = await getWarehouses(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const exportReceiptSlice = createSlice({
  name: "exportReceipts",
  initialState,
  reducers: {
    setIdleStatus: (state, action) => {
      let [key] = Object.keys(action.payload);
      state.status[key] = action.payload[key];
    },
    setStatus: (state, action) => {
      let [key] = Object.keys(action.payload);
      state.status[key] = action.payload[key];
    },
    updateExportReceipt: (state, action) => {
      state.detail = action.payload;
    },
    clearExportReceiptDetail: (state) => {
      state.detail = undefined;
    },
    clearExportReceipts: (state) => {
      state.list = undefined;
    },
    clearExportReceiptsStatistic: (state) => {
      state.statistic = undefined;
    },
    setInitExportReceipt: (state) => {
      state.list = undefined;
      state.detail = undefined;
      state.status = {};
      state.error = undefined;
      state.warehouses = undefined;
      state.users = undefined;
      state.suppliers = undefined;
      state.line_numbers = undefined;
      state.locations = undefined;
      state.inventoryError = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExportReceiptsAsync.pending, (state) => {
        state.status.list = "loading";
      })
      .addCase(getExportReceiptsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status.list = "idle";
        state.error = undefined;
      })
      .addCase(getExportReceiptsAsync.rejected, (state, action) => {
        if (action?.error?.name !== "AbortError") {
          state.status.list = "idle";
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getExportReceipts",
          };
        }
      })
      .addCase(getLocationsAsync.pending, (state) => {
        state.status.locations = "loading";
      })
      .addCase(getLocationsAsync.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.status.locations = "idle";
        state.error = undefined;
      })
      .addCase(getLocationsAsync.rejected, (state, action) => {
        state.status.locations = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getLocations",
          };
        }
      })
      .addCase(getExportReceiptDetailAsync.pending, (state) => {
        state.status.detail = "loading";
      })
      .addCase(getExportReceiptDetailAsync.fulfilled, (state, action) => {
        action.payload.line_numbers = action.payload.line_numbers.filter(
          (line) => line.status !== -1
        );
        state.detail = action.payload;
        state.status.detail = "idle";
        state.error = undefined;
      })
      .addCase(getExportReceiptDetailAsync.rejected, (state, action) => {
        if (state.status?.detail) {
          state.status.detail = "idle";
        }
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getExportReceiptDetail",
          };
        }
      })
      .addCase(getLineNumberByPositionAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(getLineNumberByPositionAsync.fulfilled, (state, action) => {
        state.line_numbers = action.payload.filter(
          (line) => line.status !== -1
        );
        state.status.position = "idle";
        state.error = undefined;
      })
      .addCase(getLineNumberByPositionAsync.rejected, (state, action) => {
        state.status.position = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getLineNumbers",
          };
        }
      })
      .addCase(postExportReceiptPositionsAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(postExportReceiptPositionsAsync.fulfilled, (state, action) => {
        state.status.position = "idle";
        state.error = undefined;
        state.inventoryError = undefined;
      })
      .addCase(postExportReceiptPositionsAsync.rejected, (state, action) => {
        state.status.position = "idle";
        // if(action?.error?.name !== "AbortError"){
        //     message.error("Lấy dữ liệu thất bại!")
        //     state.error = {
        //         payload: action.payload,
        //         title: "getPostions"
        //     }
        // }
        const { status, data } = action.payload;
        const { message: msg, field } = data[0];
        if (status === 403) {
          if (msg.includes("Cant enough quantity ")) {
            message.error("Không đủ số lượng tồn trong kho!");
          }
          if (msg.includes("List not enough quantity")) {
            let listProductError = msg.split("quantity ")[1];
            if (listProductError && JSON.parse(listProductError)) {
              state.inventoryError = JSON.parse(listProductError).map(
                (item) => item.line_number
              );
            }
            message.error("Sản phẩm không đủ tồn kho!");
          }
        }
        if (status === 404) {
          if (field === "output_bill_id") {
            message.error("Đơn xuất không tồn tại!");
          }
          if (field === "order_id") {
            message.error("Đơn hàng bán không tồn tại!");
          }
          if (field.includes("ids ")) {
            message.error(`Line number không tồn tại!`);
          }
        }
      })
      .addCase(getExportReceiptStatisticByStatusAsync.pending, (state) => {
        state.status.statistic = "loading";
      })
      .addCase(
        getExportReceiptStatisticByStatusAsync.fulfilled,
        (state, action) => {
          state.statistic = action.payload;
          state.status.statistic = "idle";
          state.error = undefined;
        }
      )
      .addCase(
        getExportReceiptStatisticByStatusAsync.rejected,
        (state, action) => {
          if (state.status?.statistic) {
            state.status.statistic = "idle";
          }
          if (action?.error?.name !== "AbortError") {
            message.error("Lấy dữ liệu thất bại!");
            state.error = {
              payload: action.payload,
              title: "getStatistic",
            };
          }
        }
      )
      .addCase(putSuggestLineNumberPositionAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(putSuggestLineNumberPositionAsync.fulfilled, (state, action) => {
        // let index = state.detail.line_numbers.findIndex(line => line.id === action.payload.id)
        // state.detail.line_numbers = action.payload.data.filter(line => line.status !== -1)
        state.detail.status = 2;
        state.status.position = "idle";
        state.error = undefined;
      })
      .addCase(putSuggestLineNumberPositionAsync.rejected, (state, action) => {
        state.status.position = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "suggestLineNumbers",
          };
        }
      })
      .addCase(postExportReceiptAsync.pending, (state) => {
        state.status.create = "loading";
      })
      .addCase(postExportReceiptAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status.create = "create_done";
        state.error = undefined;
      })
      .addCase(postExportReceiptAsync.rejected, (state, action) => {
        state.status.create = "idle";
        if (action?.error?.name !== "AbortError") {
          let { field, message: msg } = action?.payload?.[0] || {};
          if (field === "user don't have brv_code") {
            message.error("Tạo thất bại! Nhân viên chưa kích hoạt mã Bravo.");
          } else if (field === "customer_id" && msg === "Not Found") {
            message.error(
              "Không tìm thấy khách hàng trên hệ thống Bravo! Vui lòng kiểm tra lại"
            );
          } else {
            message.error("Tạo thất bại!");
          }
          state.error = {
            payload: action.payload,
            title: "postExportReceipt",
          };
        }
      })
      .addCase(putExportReceiptAsync.pending, (state) => {
        state.status.detail = "loading";
      })
      .addCase(putExportReceiptAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success("Cập nhật thành công!");
        state.status.detail = "done";
        state.error = undefined;
      })
      .addCase(putExportReceiptAsync.rejected, (state, action) => {
        state.status.detail = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại!");
          state.error = {
            payload: action.payload,
            title: "putExportReceipt",
          };
        }
      })
      .addCase(putExportReceiptPositionStatusAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(
        putExportReceiptPositionStatusAsync.fulfilled,
        (state, action) => {
          // state.detail = action.payload
          message.success("Cập nhật thành công!");
          state.status.position = "done";
          state.error = undefined;
        }
      )
      .addCase(
        putExportReceiptPositionStatusAsync.rejected,
        (state, action) => {
          state.status.position = "idle";
          // if (action?.error?.name !== "AbortError") {
          //     message.error("Cập nhật thất bại!")
          //     state.error = {
          //         payload: action.payload,
          //         title: "putExportReceiptPositionStatus"
          //     }
          // }
          const { status, data } = action.payload;
          const { message: msg, field } = data[0];
          if (status === 404) {
            if ((field = "Cant find order_id")) {
              message.error("Đơn hàng bán không tồn tại!");
            }
            if ((field = "output_bill_detail")) {
              message.error("Phiếu xuất không tồn tại!");
            }
            if (field.includes("ids ")) {
              message.error("Line number không tồn tại!");
            }
          }
          if (status === 409) {
            if (field === "status") {
              message.error("Cập nhật trùng trạng thái!");
            }
          }
          if (status === 500) {
            if (field === "Can not post Bravo : Chứng từ đã được kế toán duyệt")
              message.error(field);
          }
        }
      )
      .addCase(putExportReceiptStatusAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(putExportReceiptStatusAsync.fulfilled, (state, action) => {
        // state.detail = action.payload
        state.status.position = "done";
        state.error = undefined;
        if (action?.meta?.arg?.values?.status === -1) {
          message.success("Hủy đơn xuất thành công!");
        } else {
          message.success("Cập nhật đơn xuất thành công!");
        }
      })
      .addCase(putExportReceiptStatusAsync.rejected, (state, action) => {
        state.status.position = "idle";
        const { status, data } = action.payload;
        const { message: msg, field } = data[0];
        if (status === 409) {
          if (field === "Conflict status") {
            message.error("Cập nhật trùng trạng thái!");
          }
        }
        // if (action?.error?.name !== "AbortError") {
        //     message.error("Thay đổi trạng thái thất bại!")
        //     state.error = {
        //         payload: action.payload,
        //         title: "putLocationStatus"
        //     }
        // }
        if (status === 500) {
          if (field === "Can not post Bravo : Chứng từ đã được kế toán duyệt")
            message.error(field);
        }
      })
      .addCase(putLineNumberPositionStatusAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(putLineNumberPositionStatusAsync.fulfilled, (state, action) => {
        // let line_index = state.detail.line_numbers.findIndex(line => line.id === action.payload.output_bill_detail_id)
        // let pos_index = state.detail.line_numbers[line_index].positions.findIndex(pos => pos.id === action.payload.id)
        // state.detail.line_numbers[line_index].positions[pos_index] = action.payload
        // if(_.every(state.detail.line_numbers[line_index].positions, p => p.status === action.payload.status)){
        //     state.detail.line_numbers[line_index].status = action.payload.status
        // }
        // if(_.every(state.detail.line_numbers, line => line.status === action.payload.status)){
        //     state.detail.status = action.payload.status
        // }
        state.status.position = "done";
        state.error = undefined;
      })
      .addCase(putLineNumberPositionStatusAsync.rejected, (state, action) => {
        state.status.position = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Thay đổi trạng thái thất bại!");
          state.error = {
            payload: action.payload,
            title: "putLocationStatus",
          };
        }
      })
      .addCase(getWarehousesAsync.pending, (state) => {
        state.status.warehouse = "loading";
      })
      .addCase(getWarehousesAsync.fulfilled, (state, action) => {
        state.warehouses = action.payload.data;
        state.status.warehouse = "idle";
        state.error = undefined;
      })
      .addCase(getWarehousesAsync.rejected, (state, action) => {
        state.status.warehouse = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWarehouses",
          };
        }
      })
      .addCase(postExportReceiptPositionAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(postExportReceiptPositionAsync.fulfilled, (state, action) => {
        // let index = state.detail.positions_line_number_info.findIndex(line => line.position_id === action.payload?.data?.[0]?.position_id)
        // state.detail.positions_line_number_info[index] = action.payload?.data?.[0]
        state.status.position = "idle";
        state.error = undefined;
      })
      .addCase(postExportReceiptPositionAsync.rejected, (state, action) => {
        state.status.position = "idle";
        // if (action?.error?.name !== "AbortError") {
        //     state.error = {
        //         payload: action.payload,
        //         title: "postLineNumberPosition"
        //     }
        // }
        const { status, data } = action.payload;
        const { message: msg, field } = data[0];
        if (status === 403) {
          if (msg === "Do not enough quantity" || msg === "quantity") {
            message.error("Không đủ số lượng tồn trong kho!");
          }
        }
        if (status === 409) {
          if (
            field === "output_bill_id and output_bill_detail_id and position_id"
          ) {
            message.error("Trùng vị trí!");
          }
        }
      })
      .addCase(postLineNumberAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(postLineNumberAsync.fulfilled, (state, action) => {
        // let index = state.detail.positions_line_number_info.findIndex(line => line.position_id === action.payload?.data?.[0]?.position_id)
        // state.detail.positions_line_number_info[index] = action.payload?.data?.[0]
        state.status.position = "idle";
        state.error = undefined;
      })
      .addCase(postLineNumberAsync.rejected, (state, action) => {
        state.status.position = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Tạo dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "postLineNumber",
          };
        }
      })
      .addCase(putLineNumberPositionStatusReverseAsync.pending, (state) => {
        state.status.position = "loading";
      })
      .addCase(
        putLineNumberPositionStatusReverseAsync.fulfilled,
        (state, action) => {
          // let index = state.detail.line_numbers.findIndex(line => line.id === action.payload.output_bill_detail_id)
          // state.detail.line_numbers[index].positions.push(action.payload)
          state.status.position = "idle";
          state.error = undefined;
        }
      )
      .addCase(
        putLineNumberPositionStatusReverseAsync.rejected,
        (state, action) => {
          state.status.position = "idle";
          // if (action?.error?.name !== "AbortError") {
          //     message.error("Cập nhật dữ liệu thất bại!")
          //     state.error = {
          //         payload: action.payload,
          //         title: "putLineNumberPositionStatusReverse"
          //     }
          // }
          const { status, data } = action.payload;
          const { message: msg, field } = data[0];
          if (status === 403) {
            if (msg.includes("status")) {
              message.error("Không được phép cập nhật trạng thái!");
            }
            if (msg.includes("input_bill_detail")) {
              message.error("Phiếu xuất không tồn tại!");
            }
          }
          if (status === 409) {
            if (field === "status") {
              message.error("Cập nhật trùng trạng thái!");
            }
          }
        }
      );
  },
});

export const selectExportReceipts = (state) => state.exportReceipts.list;
export const selectExportReceiptDetail = (state) => state.exportReceipts.detail;
export const exportReceiptsStatus = (state) => state.exportReceipts.status;
export const exportReceiptsError = (state) => state.exportReceipts.error;
export const exportReceiptInventoryError = (state) =>
  state.exportReceipts.inventoryError;
export const selectWarehouses = (state) => state.exportReceipts.warehouses;
export const selectStatistic = (state) => state.exportReceipts.statistic;
export const selectLocations = (state) => state.exportReceipts.locations;

export const {
  clearExportReceiptDetail,
  clearExportReceipts,
  setIdleStatus,
  updateExportReceipt,
  setStatus,
  setInitExportReceipt,
  clearExportReceiptsStatistic,
} = exportReceiptSlice.actions;

export default exportReceiptSlice.reducer;
