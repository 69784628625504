import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getPackagings,
    getPackagingDetail,
    postPackaging,
    putPackaging,
    putPackagingStatus
} from './packagingAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
};

export const getPackagingsAsync = createAsyncThunk(
    'packagings',
    async (params, thunkAPI) => {
        try {
            const response = await getPackagings(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getPackagingDetailAsync = createAsyncThunk(
    'packagingDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getPackagingDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const postPackagingAsync = createAsyncThunk(
    'postPackaging',
    async (data, thunkAPI) => {
        try {
            const response = await postPackaging(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putPackagingAsync = createAsyncThunk(
    'putPackaging',
    async (data, thunkAPI) => {
        try {
            const response = await putPackaging(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putPackagingStatusAsync = createAsyncThunk(
    'putPackagingStatus',
    async (data, thunkAPI) => {
        try {
            const response = await putPackaging(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const packagingSlice = createSlice({
    name: 'packagings',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearPackagingDetail: state => {
            state.detail = undefined;
        },
        clearPackagings: state => {
            state.list = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getPackagingsAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPackagingsAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getPackagingsAsync.rejected, (state, action) => {
                state.status = 'idle';
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getPackagings"
                }
            })
            .addCase(getPackagingDetailAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(getPackagingDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getPackagingDetailAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getPackagingDetail"
                    }
                }
            })
            .addCase(postPackagingAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(postPackagingAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putPackagingAsync.pending, (state) => {
                state.status = 'update_loading';
            })
            .addCase(putPackagingAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                message.success("Cập nhật thành công!")
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putPackagingAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Cập nhật thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putPackaging"
                    }
                }
            })
            .addCase(putPackagingStatusAsync.pending, (state) => {
                state.status = 'update_status_loading';
            })
            .addCase(putPackagingStatusAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putPackagingStatusAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Thay đổi trạng thái thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putPackagingStatus"
                    }
                }
            })
    },
});

export const selectPackagings = state => state.packagings.list
export const selectPackagingDetail = state => state.packagings.detail
export const packagingsStatus = state => state.packagings.status
export const packagingsError = state => state.packagings.error

export const { clearPackagingDetail, clearPackagings, setIdleStatus } = packagingSlice.actions;

export default packagingSlice.reducer;

