import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getPermissions = (params) => {
    return axiosClient.get("rbac-modules", {
        params,
        baseURL: process.env.REACT_APP_API,
        headers: {
            Authorization: "Bearer " + getToken(),
        }
    })
}
const getPermissionDetail = (id) => {
    return axiosClient.get("rbac-modules/" + id, {
        baseURL: process.env.REACT_APP_API,
        headers: {
            Authorization: "Bearer " + getToken(),
        }
    })
}

const putPermission = ({id, values}) => {
    return axiosClient.put("rbac-modules/" + id, values, {
        baseURL: process.env.REACT_APP_API,
        headers: {
            Authorization: "Bearer " + getToken(),
        }
    })
}

const postPermission = (values) => {
    return axiosClient.post("rbac-modules", values, {
        baseURL: process.env.REACT_APP_API,
        headers: {
            Authorization: "Bearer " + getToken(),
        }
    })
}

export {
    getPermissions,
    getPermissionDetail,
    putPermission,
    postPermission
}