import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getInventories = (params, option) => {
    return axiosClient.get("input-bill-details", {
        params,
        ...option,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getStatisticInventory = (params) => {
    return axiosClient.get("input-bill-details-quantity/statistic", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const getPositionInventory = (params, option) => {
    return axiosClient.get("input-bill-detail-positions", {
        params,
        ...option,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const moveInventory = (data) => {
    return axiosClient.put("input-bill-details-move/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getInventories,
    getStatisticInventory,
    getPositionInventory,
    moveInventory
}