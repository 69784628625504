import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getStockAlerts,
  getStockAlertStatisticByStatus,
  putStockAlert,
} from "./stockAlertAPI";

const initialState = {
  list: undefined,
  status: "idle",
  error: undefined,
  statistic: undefined,
};

export const getStockAlertsAsync = createAsyncThunk(
  "stockAlerts",
  async (params, thunkAPI) => {
    try {
      const response = await getStockAlerts(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const putStockAlertAsync = createAsyncThunk(
  "putStockAlert",
  async (data, thunkAPI) => {
    try {
      const response = await putStockAlert(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getStockAlertStatisticByStatusAsync = createAsyncThunk(
  "getStockAlertStatisticByStatus",
  async (data, thunkAPI) => {
    try {
      const response = await getStockAlertStatisticByStatus(data);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      console.log("error", error);
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearStockAlerts: (state) => {
      state.list = undefined;
    },
    clearStockAlertStatistic: (state) => {
      state.statistic = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getStockAlertsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStockAlertsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getStockAlertsAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getstockAlerts",
          };
        }
      })
      .addCase(putStockAlertAsync.pending, (state) => {
        state.status = "update_loading";
      })
      .addCase(putStockAlertAsync.fulfilled, (state, action) => {
        message.success("Cập nhật thành công!");
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putStockAlertAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Cập nhật thất bại!");
          state.error = {
            payload: action.payload,
            title: "putStockAlert",
          };
        }
      })
      .addCase(getStockAlertStatisticByStatusAsync.pending, (state) => {
        // state.statistic.status = "loading";
      })
      .addCase(
        getStockAlertStatisticByStatusAsync.fulfilled,
        (state, action) => {
          state.statistic = action.payload;
          // state.statistic.status = "idle";
          state.error = undefined;
        }
      )
      .addCase(
        getStockAlertStatisticByStatusAsync.rejected,
        (state, action) => {
          if (state.status?.statistic) {
            // state.statistic.status = "idle";
          }
          if (action?.error?.name !== "AbortError") {
            message.error("Lấy dữ liệu thất bại!");
            state.error = {
              payload: action.payload,
              title: "getStatistic",
            };
          }
        }
      );
  },
});

export const selectStockAlerts = (state) => state.stockAlert.list;
export const stockAlertStatus = (state) => state.stockAlert.status;
export const stockAlertError = (state) => state.stockAlert.error;
export const selectStatistic = (state) => state.stockAlert.statistic;

export const { clearStockAlerts, setIdleStatus, clearStockAlertStatistic } =
  locationSlice.actions;

export default locationSlice.reducer;
