import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getPermissionDetail, getPermissions, putPermission } from './permissionAPI';

let initialState = {
    list: undefined,
    detail: undefined,
    status: {
        list: "idle",
        detail: "idle",
    },
    error: undefined
}

export const getPermissionsAsync = createAsyncThunk(
    "getPermissions",
    async (params, thunkAPI) => {
        try{
            let response = await getPermissions(params)
            return response.data
        }
        catch(error){
            thunkAPI.rejectWithValue(error.response.data)
        }
    }
)
export const getPermissionDetailAsync = createAsyncThunk(
    "getPermissionDetail",
    async (id, thunkAPI) => {
        try{
            let response = await getPermissionDetail(id)
            return response.data
        }
        catch(error){
            thunkAPI.rejectWithValue(error.response.data)
        }
    }
)
export const putPermissionAsync = createAsyncThunk(
    "putPermissions",
    async (data, thunkAPI) => {
        try{
            let response = await putPermission(data)
            return response.data
        }
        catch(error){
            thunkAPI.rejectWithValue(error.response.data)
        }
    }
)

export const PermissionSlice = createSlice({
    name: "PermissionSlice",
    initialState,
    reducers: {
        setStatus: (state, action) => {
            let [key] = Object.keys(action.payload)
            state.status[key] = action.payload[key]
        },
        clearPermissions: state => {
            state.list = undefined;
        },
        clearPermissionDetail: state => {
            state.detail = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(getPermissionsAsync.pending, (state) => {
            state.status.list = 'loading';
        })
        .addCase(getPermissionsAsync.fulfilled, (state, action) => {
            state.list = action.payload
            state.status.list = 'idle';
            state.error = undefined;
        })
        .addCase(getPermissionsAsync.rejected, (state, action) => {
            state.status.list = 'idle';
            if(action?.error?.name !== "AbortError"){
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getPermissions"
                }
            }
        })
        .addCase(getPermissionDetailAsync.pending, (state) => {
            state.status.detail = 'loading';
        })
        .addCase(getPermissionDetailAsync.fulfilled, (state, action) => {
            state.detail = action.payload
            state.status.detail = 'idle';
            state.error = undefined;
        })
        .addCase(getPermissionDetailAsync.rejected, (state, action) => {
            state.status.detail = 'idle';
            if(action?.error?.name !== "AbortError"){
                message.error("Lấy dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "getPermissionDetail"
                }
            }
        })
        .addCase(putPermissionAsync.pending, (state) => {
            state.status.detail = 'updating';
        })
        .addCase(putPermissionAsync.fulfilled, (state, action) => {
            state.detail = action.payload
            state.status.detail = 'idle';
            state.error = undefined;
        })
        .addCase(putPermissionAsync.rejected, (state, action) => {
            state.status.detail = 'idle';
            if(action?.error?.name !== "AbortError"){
                message.error("Cập nhật dữ liệu thất bại!")
                state.error = {
                    payload: action.payload,
                    title: "putPermission"
                }
            }
        })
        
    }
})

export const selectPermissions = state => state.permissions.list
export const selectPermissionDetail = state => state.permissions.detail
export const selectStatusList = state => state.permissions.status.list
export const selectStatusDetail = state => state.permissions.status.detail

export const {setStatus, clearPermissionDetail, clearPermissions} = PermissionSlice.actions

export default PermissionSlice.reducer