import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import {
  getCustomers,
  getCustomerDetail,
  postCustomer,
  putCustomer,
} from "./customerAPI";

const initialState = {
  list: undefined,
  detail: undefined,
  status: "idle",
  error: undefined,
};

export const getCustomersAsync = createAsyncThunk(
  "customers",
  async (params, thunkAPI) => {
    try {
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener("abort", () => {
        source.cancel();
      });
      const response = await getCustomers(params, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCustomerDetailAsync = createAsyncThunk(
  "customerDetail",
  async (id, thunkAPI) => {
    try {
      const response = await getCustomerDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const putCustomerAsync = createAsyncThunk(
  "putCustomer",
  async (data, thunkAPI) => {
    try {
      const response = await putCustomer(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const postCustomerAsync = createAsyncThunk(
  "postCustomer",
  async (data, thunkAPI) => {
    try {
      const response = await postCustomer(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const customerSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = "idle";
    },
    clearCustomerDetail: (state) => {
      state.detail = undefined;
    },
    clearCustomers: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getCustomersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCustomersAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getCustomersAsync.rejected, (state, action) => {
        if (action?.error?.name !== "AbortError") {
          state.status = "idle";
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getCustomers",
          };
        }
      })
      .addCase(getCustomerDetailAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCustomerDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "idle";
        state.error = undefined;
      })
      .addCase(getCustomerDetailAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getCustomerDetail",
          };
        }
      })
      .addCase(putCustomerAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(putCustomerAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(putCustomerAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Thay đổi trạng thái thất bại!");
          state.error = {
            payload: action.payload,
            title: "putCustomerDetail",
          };
        }
      })
      .addCase(postCustomerAsync.pending, (state) => {
        state.status = "detail_loading";
      })
      .addCase(postCustomerAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = "done";
        state.error = undefined;
      })
      .addCase(postCustomerAsync.rejected, (state, action) => {
        state.status = "idle";
        if (action?.error?.name !== "AbortError") {
          message.error("Không tạo được khách hàng!");
          state.error = {
            payload: action.payload,
            title: "addCustomerDetail",
          };
        }
      });
  },
});

export const selectCustomers = (state) => state.customers.list;
export const selectCustomer = (state) => state.customers.detail;
export const customersStatus = (state) => state.customers.status;
export const customersError = (state) => state.customers.error;

export const { clearCustomerDetail, clearCustomers, setIdleStatus } =
  customerSlice.actions;

export default customerSlice.reducer;
