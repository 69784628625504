import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getLocations = (params) => {
    return axiosClient.get("zones", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getAvailableLocations = (params) => {
    return axiosClient.get("zones-available", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getLocationDetail = (id) => {
    return axiosClient.get("zones/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postLocation = (data) => {
    return axiosClient.post("zones", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putLocation = (data) => {
    return axiosClient.put("zones/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

// const putLocationStatus = (id, data) => {
//     return axiosClient.put("suppliers-status/" + id, data, {
//         headers: {
//             Authorization: "Bearer " + getToken()
//         }
//     })
// }

export {
    getLocations,
    getAvailableLocations,
    getLocationDetail,
    postLocation,
    putLocation,
    // putLocationStatus
}