import axiosClient from "../axiosClient";

let abortList = {};

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getUsers = (params) => {
  if (abortList.users) {
    abortList.users.abort();
    abortList.users = undefined;
  }
  abortList.users = new AbortController();
  let res = axiosClient.get("users", {
    signal: abortList.users.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return res;
};

const getSales = (params) => {
  if (abortList.sales) {
    abortList.sales.abort();
    abortList.sales = undefined;
  }
  abortList.sales = new AbortController();
  let res = axiosClient.get("users", {
    signal: abortList.sales.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return res;
};
const getAccountants = (params) => {
  if (abortList.accountants) {
    abortList.accountants.abort();
    abortList.accountants = undefined;
  }
  abortList.accountants = new AbortController();
  let res = axiosClient.get("users", {
    signal: abortList.accountants.signal,
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
  return res;
};

const getBrvUsers = (params) => {
  return axiosClient.get("brv-users", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getUserDetail = (id) => {
  return axiosClient.get("users/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postUser = (data) => {
  return axiosClient.post("users", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putUser = (data) => {
  return axiosClient.put("users/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putUserStatus = (id, data) => {
  return axiosClient.put("users-status/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putUserPassword = (data) => {
  return axiosClient.put("password", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putPasswordForUser = (id, data) => {
  return axiosClient.put("users-password/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getUsers,
  getSales,
  getAccountants,
  getBrvUsers,
  getUserDetail,
  postUser,
  putUser,
  putUserStatus,
  putUserPassword,
  putPasswordForUser,
};
