import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getExportReceipts = (params, option) => {
  return axiosClient.get("output-bills", {
    params,
    ...option,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getExportReceiptDetail = (id) => {
  return axiosClient.get("output-bills/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getLineNumberByPosition = (params) => {
  return axiosClient.get("output-bill-detail-positions", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const postExportReceiptPositions = ({ id, values }) => {
  return axiosClient.post("output-bill-details/position/" + id, values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getExportReceiptStatisticByStatus = (params) => {
  return axiosClient.get("output-bills/statistic/status", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putSuggestLineNumberPosition = ({ id, values }) => {
  return axiosClient.put("output-bill-details/position/" + id, values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postExportReceipt = (data) => {
  return axiosClient.post("output-bills", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putExportReceipt = (data) => {
  return axiosClient.put("output-bills/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putExportReceiptStatus = (data) => {
  return axiosClient.put("output-bills-status/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putExportReceiptPositionStatus = (data) => {
  return axiosClient.put("output-bill-detail-positions-status", data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postLineNumber = (data) => {
  return axiosClient.post("output-bill-details", data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putLineNumberQuantity = (data) => {
  return axiosClient.put("output-bill-details/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putLineNumberStatus = (data) => {
  return axiosClient.put("output-bill-details-status/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putLineNumberPosition = (data) => {
  return axiosClient.put(
    "output-bill-detail-positions/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};
const putLineNumberPositionStatus = (data) => {
  return axiosClient.put(
    "output-bill-detail-positions-status/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const putLineNumberPositionStatusReverse = (data) => {
  return axiosClient.put(
    "output-bill-detail-positions-status-reverse/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};

const postExportReceiptPosition = (data) => {
  return axiosClient.post("output-bill-detail-positions", data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putAllLineNumber = (data) => {
  return axiosClient.put("output-bill-details-list", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const countExportPrint = (id, data) => {
  return axiosClient.put("output-bills-print/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getExportReceipts,
  getExportReceiptDetail,
  getExportReceiptStatisticByStatus,
  postExportReceipt,
  putExportReceipt,
  putExportReceiptStatus,
  getLineNumberByPosition,
  postLineNumber,
  putLineNumberQuantity,
  putLineNumberStatus,
  putLineNumberPosition,
  putLineNumberPositionStatus,
  putSuggestLineNumberPosition,
  postExportReceiptPosition,
  putLineNumberPositionStatusReverse,
  postExportReceiptPositions,
  putExportReceiptPositionStatus,
  putAllLineNumber,
  countExportPrint,
};
