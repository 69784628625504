import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getStackTactic = (params) => {
    return axiosClient.get("tactics", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getStackTacticDetail = (id) => {
    return axiosClient.get("tactics/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postStackTactic = (data) => {
    return axiosClient.post("tactics", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putStackTactic = (data) => {
    return axiosClient.put("tactics/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getStackTactic,
    getStackTacticDetail,
    postStackTactic,
    putStackTactic,
}