import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/Auth/authSlice";
import userReducer from "../features/user/userSlice";
import langReducer from "../features/lang/langSlice";
import newsReducer from "../features/news/newsSlice";
import supplierReducer from "../features/supplier/supplierSlice";
import customerReducer from "../features/customer/customerSlice";
import locationReducer from "../features/location/locationSlice";
import packagingReducer from "../features/packaging/packagingSlice";
import transporterReducer from "../features/transporter/transporterSlice";
import warehousesReducer from "../features/warehouse/warehouseSlice";
import zonesReducer from "../features/zone/zoneSlice";
import productReducer from "../features/product/productSlice";
import importReceiptsReducer from "../features/importReceipt/importReceiptSlice";
import exportReceiptsReducer from "../features/exportReceipt/exportReceiptSlice";
import inventoryReducer from "../features/inventory/inventorySlice";
import editInventoryReducer from "../features/editInventory/editInventorySlice";
import stackTacticReducer from "../features/stackTactic/stackTacticSlice";
import DashboardReducer from "../features/dashboard/dashboardSlice";
import UserGroupReducer from "../features/userGroup/userGroupSlice";
import PermissionReducer from "../features/permission/permissionSlice";
import ReportsReducer from "../features/reports/InventoryReportSlice";
import BusinessReceiptsReducer from "../features/businessReceipt/businessReceiptSlice";
import stockAlertReducer from "features/stockAlert/stockAlertSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    users: userReducer,
    lang: langReducer,
    news: newsReducer,
    suppliers: supplierReducer,
    customers: customerReducer,
    locations: locationReducer,
    packagings: packagingReducer,
    transporters: transporterReducer,
    warehouses: warehousesReducer,
    zones: zonesReducer,
    products: productReducer,
    importReceipts: importReceiptsReducer,
    exportReceipts: exportReceiptsReducer,
    inventory: inventoryReducer,
    editInventory: editInventoryReducer,
    stackTactic: stackTacticReducer,
    dashboard: DashboardReducer,
    userGroups: UserGroupReducer,
    permissions: PermissionReducer,
    reports: ReportsReducer,
    businessReceipts: BusinessReceiptsReducer,
    stockAlert: stockAlertReducer,
  },
});
