import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getUserGroups = (params) => {
    return axiosClient.get("rbac-roles", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getUserGroupDetail = ({id, values}) => {
    return axiosClient.get("rbac-roles/" + id, {
        params: values,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putUserGroup = ({id, values}) => {
    return axiosClient.put("rbac-roles/" + id, values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postUserGroup = (values) => {
    return axiosClient.post("rbac-roles", values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getUserGroups,
    getUserGroupDetail,
    putUserGroup,
    postUserGroup
}