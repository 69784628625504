import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    getNews
} from './newsAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
};

export const getNewsAsync = createAsyncThunk(
    'news',
    async (data) => {
        try{
            const response = await getNews(data);
            return response.data;
        } catch(error){
            return error
        }
    }
);
  
export const newsSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
        // changeLang: (state, action) => {
        //     console.log("selected action", action)
        //     state.lang = action.payload
        // }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
      builder
        .addCase(getNewsAsync.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(getNewsAsync.fulfilled, (state, action) => {
            state.list = action.payload
            state.status = 'idle';
        });
    },
});

export const newsStatus = state => state.news.status
export const selectNews = state => state.news.list
export const selectNewsDetail = state => state.news.detail

export const { } = newsSlice.actions;
  
export default newsSlice.reducer;
  
