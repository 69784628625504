import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getProducts = (params, options) => {
    return axiosClient.get("products-detail", {
        params,
        ...options,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getProductDetail = ({id, values}) => {
    return axiosClient.get("products-detail/" + id, {
        params: values,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getProductsByUnit = (params, order) => {
    return axiosClient.get("products-detail", {
        params,
        data: {
            order
        },
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postProduct = (data) => {
    return axiosClient.post("products", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putProduct = (data) => {
    return axiosClient.put("products-detail/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putProductStatus = (id, data) => {
    return axiosClient.put("products-status/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getProducts,
    getProductDetail,
    postProduct,
    putProduct,
    putProductStatus,
    getProductsByUnit
}