import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getStockAlerts = (params, option) => {
  return axiosClient.get("inventory-warning", {
    params,
    ...option,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postStockAlert = (data) => {
  return axiosClient.post("inventory-warning", data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putStockAlert = (data) => {
  return axiosClient.put("inventory-warning", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const getStockAlertStatisticByStatus = (params) => {
  return axiosClient.get("inventory-warning-statistics", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getStockAlerts,
  putStockAlert,
  getStockAlertStatisticByStatus,
  postStockAlert,
};
