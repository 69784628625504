import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getSuppliers = (params, option) => {
    return axiosClient.get("suppliers", {
        params,
        ...option,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getSupplierDetail = (id) => {
    return axiosClient.get("suppliers/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postSupplier = (data) => {
    return axiosClient.post("suppliers", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putSupplier = (data) => {
    return axiosClient.put("suppliers/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putSupplierStatus = (id, data) => {
    return axiosClient.put("suppliers-status/" + id, data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

export {
    getSuppliers,
    getSupplierDetail,
    postSupplier,
    putSupplier,
    putSupplierStatus
}