import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
    getZones,
    getZoneDetail,
    postZone,
    putZone,
    getZonesAvailable
} from './zoneAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    status: 'idle',
    error: undefined,
    available: undefined
};

export const getZonesAsync = createAsyncThunk(
    'zones',
    async (params, thunkAPI) => {
        try {
            const response = await getZones(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getZoneDetailAsync = createAsyncThunk(
    'zoneDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getZoneDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const postZoneAsync = createAsyncThunk(
    'postZone',
    async (data, thunkAPI) => {
        try {
            const response = await postZone(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putZoneAsync = createAsyncThunk(
    'putZone',
    async (data, thunkAPI) => {
        try {
            const response = await putZone(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putZoneStatusAsync = createAsyncThunk(
    'putZoneStatus',
    async (data, thunkAPI) => {
        try {
            const response = await putZone(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getZonesAvailableAsync = createAsyncThunk(
    'zonesAvailable',
    async (params, thunkAPI) => {
        try {
            const response = await getZonesAvailable(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const zoneSlice = createSlice({
    name: 'zones',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearZoneDetail: state => {
            state.detail = undefined;
        },
        clearZones: state => {
            state.list = undefined;
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getZonesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getZonesAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getZonesAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getZones"
                    }
                }
            })
            .addCase(getZoneDetailAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(getZoneDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getZoneDetailAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getZoneDetail"
                    }
                }
            })
            .addCase(postZoneAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(postZoneAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putZoneAsync.pending, (state) => {
                state.status = 'update_loading';
            })
            .addCase(putZoneAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                message.success("Cập nhật thành công!")
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putZoneAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Cập nhật thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putZone"
                    }
                }
            })
            .addCase(putZoneStatusAsync.pending, (state) => {
                state.status = 'update_status_loading';
            })
            .addCase(putZoneStatusAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putZoneStatusAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Thay đổi trạng thái thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putZoneStatus"
                    }
                }
            })
            .addCase(getZonesAvailableAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getZonesAvailableAsync.fulfilled, (state, action) => {
                state.available = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getZonesAvailableAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getZones"
                    }
                }
            })
    },
});

export const selectZones = state => state.zones.list
export const selectAvailableZones = state => state.zones.available
export const selectZoneDetail = state => state.zones.detail
export const zonesStatus = state => state.zones.status
export const zonesError = state => state.zones.error

export const { clearZoneDetail, clearZones, setIdleStatus } = zoneSlice.actions;

export default zoneSlice.reducer;

