import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import {
  getExportReports,
  getImportReports,
  getInventoryReports,
  getWarehouseCardReports,
} from "./InventoryReportAPI";

const initialState = {
  list: undefined,
  importReport: undefined,
  exportReport: undefined,
  warehouseCardReport: undefined,
  status: {
    list: "idle",
    importReport: "idle",
    exportReport: "idle",
    warehouseCardReport: "idle",
  },
  error: undefined,
};

export const getInventoryReportAsync = createAsyncThunk(
  "getInventoryReports",
  async (params, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await getInventoryReports(params, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getImportReportAsync = createAsyncThunk(
  "getImportReports",
  async (params, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await getImportReports(params, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getExportReportAsync = createAsyncThunk(
  "getExportReports",
  async (params, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await getExportReports(params, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getWarehouseCardReportAsync = createAsyncThunk(
  "getWarehouseCardReports",
  async (params, thunkAPI) => {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener("abort", () => {
      source.cancel();
    });
    try {
      const response = await getWarehouseCardReports(params, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const InventoryReportSlice = createSlice({
  name: "inventoryReports",
  initialState,
  reducers: {
    setStatus: (state, action) => {
      let [key] = Object.keys(action.payload);
      state.status[key] = action.payload[key];
    },
    clearInventoryReports: (state) => {
      state.list = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInventoryReportAsync.pending, (state) => {
        state.status.list = "loading";
      })
      .addCase(getInventoryReportAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status.list = "idle";
        state.error = undefined;
      })
      .addCase(getInventoryReportAsync.rejected, (state, action) => {
        if (action?.error?.name !== "AbortError") {
          state.status.list = "idle";
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getImportReceipts",
          };
        }
      })
      .addCase(getImportReportAsync.pending, (state) => {
        state.status.importReport = "loading";
      })
      .addCase(getImportReportAsync.fulfilled, (state, action) => {
        state.importReport = action.payload;
        state.status.importReport = "idle";
        state.error = undefined;
      })
      .addCase(getImportReportAsync.rejected, (state, action) => {
        if (action?.error?.name !== "AbortError") {
          state.status.importReport = "idle";
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getImportReceipts",
          };
        }
      })
      .addCase(getExportReportAsync.pending, (state) => {
        state.status.exportReport = "loading";
      })
      .addCase(getExportReportAsync.fulfilled, (state, action) => {
        state.exportReport = action.payload;
        state.status.exportReport = "idle";
        state.error = undefined;
      })
      .addCase(getExportReportAsync.rejected, (state, action) => {
        if (action?.error?.name !== "AbortError") {
          state.status.exportReport = "idle";
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getExportReceipts",
          };
        }
      })
      .addCase(getWarehouseCardReportAsync.pending, (state) => {
        state.status.warehouseCardReport = "loading";
      })
      .addCase(getWarehouseCardReportAsync.fulfilled, (state, action) => {
        state.warehouseCardReport = action.payload;
        state.status.warehouseCardReport = "idle";
        state.error = undefined;
      })
      .addCase(getWarehouseCardReportAsync.rejected, (state, action) => {
        if (action?.error?.name !== "AbortError") {
          state.status.warehouseCardReport = "idle";
          message.error("Lấy dữ liệu thất bại!");
          state.error = {
            payload: action.payload,
            title: "getWarehouseCardReceipts",
          };
        }
      });
  },
});

export const selectReportList = (state) => state.reports.list;
export const reportStatus = (state) => state.reports.status;
export const selectImportReportList = (state) => state.reports.importReport;
export const selectExportReportList = (state) => state.reports.exportReport;
export const selectWarehouseCardReportList = (state) =>
  state.reports.warehouseCardReport;

export const { setStatus } = InventoryReportSlice.actions;

export default InventoryReportSlice.reducer;
