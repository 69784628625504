import React, { Suspense } from 'react';
import './App.css';
import './styles/print.css';
import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom'
import { Spin } from 'antd';

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./containers/Login'));

function App() {
  return (
    <Suspense 
      fallback={
        <div className="loading-component">
          <Spin size="large" tip="Loading..."/>
        </div>
      }
    >
      <BrowserRouter>
        <Switch>
          <Redirect exact from= "/" to="/login" />
          <Route exact path="/login" component={Login} />
          <Route path="/" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
