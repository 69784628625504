import axiosClient from "../axiosClient"

const getToken = () => {
    return (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined;
}

const getPackagings = (params) => {
    return axiosClient.get("specifications", {
        params,
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}
const getPackagingDetail = (id) => {
    return axiosClient.get("specifications/" + id, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const postPackaging = (data) => {
    return axiosClient.post("specifications", data, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

const putPackaging = (data) => {
    return axiosClient.put("specifications/" + data.id, data.values, {
        headers: {
            Authorization: "Bearer " + getToken()
        }
    })
}

// const putPackagingStatus = (id, data) => {
//     return axiosClient.put("suppliers-status/" + id, data, {
//         headers: {
//             Authorization: "Bearer " + getToken()
//         }
//     })
// }

export {
    getPackagings,
    getPackagingDetail,
    postPackaging,
    putPackaging,
    // putPackagingStatus
}