import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import _ from 'lodash';
import { statuses } from '../../common/Warehouses';
import {
    getEditInventories,
    getEditInventoryDetail,
    postEditInventory,
    putEditInventory,
} from './editInventoryAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    current_editInventory: undefined,
    activeList: undefined,
    status: 'idle',
    error: undefined,
};

export const getEditInventoriesAsync = createAsyncThunk(
    'editInventory',
    async (params, thunkAPI) => {
        try {
            const response = await getEditInventories(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getEditInventoryDetailAsync = createAsyncThunk(
    'editInventoryDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getEditInventoryDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const postEditInventoryAsync = createAsyncThunk(
    'postEditInventory',
    async (data, thunkAPI) => {
        try {
            const response = await postEditInventory(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putEditInventoryAsync = createAsyncThunk(
    'putEditInventory',
    async (data, thunkAPI) => {
        try {
            const response = await putEditInventory(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putEditInventoryStatusAsync = createAsyncThunk(
    'putWarehouseStatus',
    async (data, thunkAPI) => {
        try {
            const response = await putEditInventory(data);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const editInventorySlice = createSlice({
    name: 'editInventory',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearEditInventoryDetail: state => {
            state.detail = undefined;
        },
        clearEditInventories: state => {
            state.list = undefined;
        },
        changeEditInventory: (state, action) => {
            state.current_editInventory = action.payload
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getEditInventoriesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEditInventoriesAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.activeList = action.payload.data.filter(item => item.status * 1 === statuses[0].id * 1)
                
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getEditInventoriesAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getWarehouses"
                    }
                }
            })
            .addCase(getEditInventoryDetailAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(getEditInventoryDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getEditInventoryDetailAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getWarehouseDetail"
                    }
                }
            })
            .addCase(postEditInventoryAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(postEditInventoryAsync.fulfilled, (state, action) => {
                state.detail = action.payload;
                message.success("Tạo phiếu thành công!")
                state.status = 'create_done';
                state.error = undefined;
            })
            .addCase(postEditInventoryAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Tạo phiếu thất bại! SL khả dụng không thể nhỏ hơn SL điều chỉnh")
                    state.error = {
                        payload: action.payload,
                        title: "postWarehouse"
                    }
                }
            })
            .addCase(putEditInventoryAsync.pending, (state) => {
                state.status = 'update_loading';
            })
            .addCase(putEditInventoryAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.activeList = updateEditInventory(state.activeList, action.payload)
                message.success("Cập nhật thành công!")
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putEditInventoryAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Cập nhật thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putWarehouse"
                    }
                }
            })
            .addCase(putEditInventoryStatusAsync.pending, (state) => {
                state.status = 'update_status_loading';
            })
            .addCase(putEditInventoryStatusAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'done';
                state.error = undefined;
            })
            .addCase(putEditInventoryStatusAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Thay đổi trạng thái thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putWarehouseStatus"
                    }
                }
            })
    },
});

const updateEditInventory = (_data, changedValue) => {
    let index = _data.findIndex(i => i.id === changedValue.id)
    _data[index] = {...changedValue}
    return _data
}

export const selectEditInventories = state => state.editInventory.list
export const selectActiveEditInventories = state => state.editInventory.activeList
export const selectCurrentEditInventories = state => state.editInventory.current_warehouse
export const selectEditInventoryDetail = state => state.editInventory.detail
export const editInventoriesStatus = state => state.editInventory.status
export const editInventoriesError = state => state.editInventory.error

export const { clearEditInventoryDetail, clearEditInventories, setIdleStatus, changeEditInventory } = editInventorySlice.actions;

export default editInventorySlice.reducer;

