import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import _ from 'lodash';
import { statuses } from '../../common/Warehouses';
import {
    getStackTactic,
    getStackTacticDetail,
    postStackTactic,
    putStackTactic,
} from './stackTacticAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    activeList: undefined,
    status: 'idle',
    error: undefined,
};

export const getStackTacticAsync = createAsyncThunk(
    'stackTactic',
    async (params, thunkAPI) => {
        try {
            const response = await getStackTactic(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getStackTacticDetailAsync = createAsyncThunk(
    'stackTacticDetail',
    async (id, thunkAPI) => {
        try {
            const response = await getStackTacticDetail(id);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const postStackTacticAsync = createAsyncThunk(
    'postStackTactic',
    async (data, thunkAPI) => {
        try {
            const response = await postStackTactic(data);
            if(data?.parent_id){
                thunkAPI.dispatch(getStackTacticDetailAsync(data.parent_id))
            }
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const putStackTacticAsync = createAsyncThunk(
    'putStackTactic',
    async (data, thunkAPI) => {
        try {
            const response = await putStackTactic(data);
            console.log(data, response)
            if(response?.data?.parent_id){
                thunkAPI.dispatch(getStackTacticDetailAsync(response.data.parent_id))
            }
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const stackTacticSlice = createSlice({
    name: 'stackTactic',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearStackTacticDetail: state => {
            state.detail = undefined;
        },
        clearStackTactic: state => {
            state.list = undefined;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getStackTacticAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getStackTacticAsync.fulfilled, (state, action) => {
                state.list = {
                    ...action.payload, 
                    data: action.payload.data.map(d => {
                        d.tactic_childs = d?.children || []
                        delete d.children
                        return d
                    })
                }
                state.activeList = action.payload.data.filter(item => item.status * 1 === statuses[0].id * 1)
                
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getStackTacticAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getStackTactic"
                    }
                }
            })
            .addCase(getStackTacticDetailAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(getStackTacticDetailAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getStackTacticDetailAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getStackTacticDetail"
                    }
                }
            })
            .addCase(postStackTacticAsync.pending, (state) => {
                state.status = 'detail_loading';
            })
            .addCase(postStackTacticAsync.fulfilled, (state, action) => {
                if(!action.meta.arg?.parent_id){
                    state.detail = action.payload;
                }
                message.success("Tạo chiến thuật thành công!")
                state.status = 'create_done';
                state.error = undefined;
            })
            .addCase(postStackTacticAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Tạo chiến thuật thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "postStackTactic"
                    }
                }
            })
            .addCase(putStackTacticAsync.pending, (state) => {
                state.status = 'update_loading';
            })
            .addCase(putStackTacticAsync.fulfilled, (state, action) => {
                state.detail = action.payload
                message.success("Cập nhật thành công!")
                state.status = 'update_done';
                state.error = undefined;
            })
            .addCase(putStackTacticAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Cập nhật thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "putStackTactic"
                    }
                }
            })
    },
});

export const selectStackTactic = state => state.stackTactic.list
export const selectActiveStackTactic = state => state.stackTactic.activeList
export const selectStackTacticDetail = state => state.stackTactic.detail
export const stackTacticStatus = state => state.stackTactic.status
export const stackTacticError = state => state.stackTactic.error

export const { clearStackTacticDetail, clearStackTactic, setIdleStatus } = stackTacticSlice.actions;

export default stackTacticSlice.reducer;

