import axiosClient from "../axiosClient";

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.token) || undefined
  );
};

const getImportReceipts = (params, option) => {
  return axiosClient.get("input-bills", {
    params,
    ...option,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getImportReceiptDetail = (id) => {
  return axiosClient.get("input-bills/" + id, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getLineNumberByPosition = (params) => {
  return axiosClient.get("input-bill-detail-positions", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getImportReceiptStatisticByStatus = (params) => {
  return axiosClient.get("input-bills/statistics/status", {
    params,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const getAvailablePosition = (params, option) => {
  return axiosClient.get("input-bill-details-location-stock", {
    params,
    ...option,
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postSuggestLineNumberPosition = ({ id, values }) => {
  return axiosClient.post("input-bill-details/position/" + id, values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const postImportReceipt = (data) => {
  return axiosClient.post("input-bills", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putImportReceipt = (data) => {
  return axiosClient.put("input-bills/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putImportReceiptStatus = (data) => {
  return axiosClient.put("input-bills-status/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putImportReceiptStatusReverse = (data) => {
  return axiosClient.put(
    "input-bill-detail-positions-status-reverse/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};
const postLineNumber = (data) => {
  return axiosClient.post("input-bill-details", data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const postLineNumberPosition = (data) => {
  return axiosClient.post("input-bill-detail-positions", data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putLineNumberQuantity = (data) => {
  return axiosClient.put("input-bill-details/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putLineNumberStatus = (data) => {
  return axiosClient.put("input-bill-details-status/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};
const putLineNumberPosition = (data) => {
  return axiosClient.put(
    "input-bill-detail-positions/" + data.id,
    data.values,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    }
  );
};
const putLineNumberPositionStatus = (data) => {
  return axiosClient.put("input-bill-detail-positions-status", data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putLineNumberPositionMove = (data) => {
  return axiosClient.put("input-bill-details-move/" + data.id, data.values, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putLineNumberPositionMoveNew = (data) => {
  return axiosClient.post("input-bill-detail-positions", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const putAllLineNumber = (data) => {
  return axiosClient.put("input-bill-details-list", data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

const countImportPrint = (id, data) => {
  return axiosClient.put("input-bills-print/" + id, data, {
    headers: {
      Authorization: "Bearer " + getToken(),
    },
  });
};

export {
  getImportReceipts,
  getImportReceiptDetail,
  getImportReceiptStatisticByStatus,
  postImportReceipt,
  putImportReceipt,
  putImportReceiptStatus,
  getLineNumberByPosition,
  postLineNumber,
  postLineNumberPosition,
  putLineNumberQuantity,
  putLineNumberStatus,
  putLineNumberPosition,
  putLineNumberPositionStatus,
  postSuggestLineNumberPosition,
  putImportReceiptStatusReverse,
  putLineNumberPositionMove,
  getAvailablePosition,
  putLineNumberPositionMoveNew,
  putAllLineNumber,
  countImportPrint,
};
