import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { statuses } from '../../common/Warehouses';
import {
    getInventories,
    getStatisticInventory,
    getPositionInventory,
    moveInventory
} from './inventoryAPI';

const initialState = {
    list: undefined,
    detail: undefined,
    current_inventory: undefined,
    activeList: undefined,
    status: 'idle',
    error: undefined,
    statistic: []
};

export const getInventoriesAsync = createAsyncThunk(
    'inventory',
    async (params, thunkAPI) => {
        try {
            const response = await getInventories(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getInventoriesStatisticAsync = createAsyncThunk(
    'inventoryStatistic',
    async (params, thunkAPI) => {
        try {
            const response = await getStatisticInventory(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const getInventoriesPositionAsync = createAsyncThunk(
    'inventoryPosition',
    async (params, thunkAPI) => {
        const source = axios.CancelToken.source()
        thunkAPI.signal.addEventListener('abort', () => {
            source.cancel()
        })
        try {
            const response = await getPositionInventory(params, {
                cancelToken: source.token,
            });
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);
export const moveInventoryAsync = createAsyncThunk(
    'inventoryMove',
    async (params, thunkAPI) => {
        try {
            const response = await moveInventory(params);
            // The value we return becomes the `fulfilled` action payload
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data)
        }
    }
);

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState,
    reducers: {
        setIdleStatus: state => {
            state.status = "idle"
        },
        clearInventories: state => {
            state.list = undefined;
        },
        changeInventory: (state, action) => {
            state.current_Inventory = action.payload
        }
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(getInventoriesAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getInventoriesAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.activeList = action.payload.data.filter(item => item.status * 1 === statuses[0].id * 1)
                
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getInventoriesAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getInventories"
                    }
                }
            })
            .addCase(getInventoriesStatisticAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getInventoriesStatisticAsync.fulfilled, (state, action) => {
                state.statistic = action.payload
                
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getInventoriesStatisticAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "inventoryStatistic"
                    }
                }
            })
            .addCase(getInventoriesPositionAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getInventoriesPositionAsync.fulfilled, (state, action) => {
                state.list = action.payload
                state.activeList = action.payload.data.filter(item => item.status * 1 === statuses[0].id * 1)
                
                state.status = 'idle';
                state.error = undefined;
            })
            .addCase(getInventoriesPositionAsync.rejected, (state, action) => {
                if(action?.error?.name !== "AbortError"){
                    state.status = 'idle';
                    message.error("Lấy dữ liệu thất bại!")
                    state.error = {
                        payload: action.payload,
                        title: "getInventories"
                    }
                }
            })
            .addCase(moveInventoryAsync.pending, (state) => {
                state.status = 'update_loading';
            })
            .addCase(moveInventoryAsync.fulfilled, (state, action) => {
                message.success("Di chuyển vị trí thành công!")
                state.status = 'move_done';
                state.error = undefined;
            })
            .addCase(moveInventoryAsync.rejected, (state, action) => {
                state.status = 'idle';
                if(action?.error?.name !== "AbortError"){
                    const error = action?.payload?.[0]
                    if(error?.message === "Can't move form type_zone 1 to type_zone 2"){
                        message.error("Không được di chuyển hàng lẻ qua hàng chẵn!")
                    }
                    else if(error?.message === "Can not move one position"){
                        message.error("Không được di chuyển cùng 1 vị trí!")
                    }
                    else{
                        message.error("Di chuyển vị trí thất bại!")
                    }
                    state.error = {
                        payload: action.payload,
                        title: "inventoryMove"
                    }
                }
            })
    },
});

export const selectInventories = state => state.inventory.list
export const selectActiveInventories = state => state.inventory.activeList
export const selectCurrentInventories = state => state.inventory.current_warehouse
export const selectInventoryStatistic = state => state.inventory.statistic
export const inventoriesStatus = state => state.inventory.status
export const inventoriesError = state => state.inventory.error

export const { clearInventories, setIdleStatus, changeInventory } = inventorySlice.actions;

export default inventorySlice.reducer;

