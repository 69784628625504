import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import {
  getSuppliers,
  getSupplierDetail,
  postSupplier,
  putSupplier,
  putSupplierStatus
} from './supplierAPI';

const initialState = {
  list: undefined,
  detail: undefined,
  status: 'idle',
  error: undefined,
};

export const getSuppliersAsync = createAsyncThunk(
  'suppliers',
  async (params, thunkAPI) => {
    const source = axios.CancelToken.source()
    thunkAPI.signal.addEventListener('abort', () => {
        source.cancel()
    })
    try {
      const response = await getSuppliers(params, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);
export const getSupplierDetailAsync = createAsyncThunk(
  'supplierDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getSupplierDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);
export const postSupplierAsync = createAsyncThunk(
  'postSupplier',
  async (data, thunkAPI) => {
    try {
      const response = await postSupplier(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);
export const putSupplierAsync = createAsyncThunk(
  'putSupplier',
  async (data, thunkAPI) => {
    try {
      const response = await putSupplier(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);
export const putSupplierStatusAsync = createAsyncThunk(
  'putSupplierStatus',
  async (data, thunkAPI) => {
    try {
      const response = await putSupplier(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
);

export const supplierSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    setIdleStatus: state => {
      state.status = "idle"
    },
    clearSupplierDetail: state => {
      state.detail = undefined;
    },
    clearSuppliers: state => {
      state.list = undefined;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getSuppliersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSuppliersAsync.fulfilled, (state, action) => {
        state.list = action.payload
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getSuppliersAsync.rejected, (state, action) => {
        if(action?.error?.name !== "AbortError"){
          state.status = 'idle';
          message.error("Lấy dữ liệu thất bại!")
          state.error = {
            payload: action.payload,
            title: "getSuppliers"
          }
        }
      })
      .addCase(getSupplierDetailAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(getSupplierDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getSupplierDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if(action?.error?.name !== "AbortError"){
          message.error("Lấy dữ liệu thất bại!")
          state.error = {
            payload: action.payload,
            title: "getSupplierDetail"
          }
        }
      })
      .addCase(postSupplierAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(postSupplierAsync.fulfilled, (state, action) => {
        state.detail = action.payload
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putSupplierAsync.pending, (state) => {
        state.status = 'update_loading';
      })
      .addCase(putSupplierAsync.fulfilled, (state, action) => {
        state.detail = action.payload
        message.success("Cập nhật thành công!")
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putSupplierAsync.rejected, (state, action) => {
        state.status = 'idle';
        if(action?.error?.name !== "AbortError"){
          message.error("Cập nhật thất bại!")
          state.error = {
            payload: action.payload,
            title: "putSupplier"
          }
        }
      })
      .addCase(putSupplierStatusAsync.pending, (state) => {
        state.status = 'update_status_loading';
      })
      .addCase(putSupplierStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putSupplierStatusAsync.rejected, (state, action) => {
        state.status = 'idle';
        if(action?.error?.name !== "AbortError"){
          message.error("Thay đổi trạng thái thất bại!")
          state.error = {
            payload: action.payload,
            title: "putSupplierStatus"
          }
        }
      })
  },
});

export const selectSuppliers = state => state.suppliers.list
export const selectSupplierDetail = state => state.suppliers.detail
export const suppliersStatus = state => state.suppliers.status
export const suppliersError = state => state.suppliers.error

export const { clearSupplierDetail, clearSuppliers, setIdleStatus } = supplierSlice.actions;

export default supplierSlice.reducer;

